import { LoginSession } from '@core/entities/login/login-session.entity';
import { LoginFormEntries } from '@core/enums/login-form-entries.enum';

export class LoginFormRedirect {
  private _component: LoginFormEntries;
  private _loginSession: LoginSession;

  get component(): LoginFormEntries {
    return this._component;
  }

  /**
   * @param component
   */
  set component(component: LoginFormEntries) {
    this._component = component;
  }

  get loginSession(): LoginSession {
    return this._loginSession;
  }

  /**
   * @param loginSession
   */
  set loginSession(loginSession: LoginSession) {
    this._loginSession = loginSession;
  }
}
