<h1>
  Sett nytt personlig passord
  <span class="subtitle">Velg passord for kontoen din som må oppgis ved innlogging</span>
</h1>

@if (processing && !submitted) {
  <app-ff-loading-indicator stroke="#2c2c2c"></app-ff-loading-indicator>
}

@if (resetToken.checked && resetToken.valid && !resetToken.resetCompleted) {
  <form
    #formRef
    (keyup.enter)="nextStep()"
    [formGroup]="passwordResetForm"
    [class.submitted]="submitted"
    data-cy="form-password-reset"
    class="form"
    >
    <div class="form-group">
      <div class="form-control">
        <label for="password">Nytt personlig passord</label>
        <app-ff-toggle-reveal-input [showToggleButton]="true">
          <input
            #focusRef
            (input)="showErrorsIfSubmitted()"
            [formControl]="formHelper.getCtrl(passwordResetForm, 'password')"
            [readOnly]="processing ? true : null"
            autocomplete="new-password"
            data-cy="input-password"
            id="password"
            type="password"
            />
        </app-ff-toggle-reveal-input>
      </div>
    </div>
    <div class="form-group">
      <div class="form-control">
        <label for="confirm-password">Gjenta nytt passord</label>
        <app-ff-toggle-reveal-input [showToggleButton]="true">
          <input
            (input)="showErrorsIfSubmitted()"
            [formControl]="formHelper.getCtrl(passwordResetForm, 'confirmPassword')"
            id="confirm-password"
            [readOnly]="processing ? true : null"
            autocomplete="new-password"
            data-cy="input-password-repeat"
            type="password"
            />
        </app-ff-toggle-reveal-input>
      </div>
    </div>
    <app-ff-button
      (clicked)="nextStep()"
      [processing]="processing"
      data-cy="button-next"
      label="Lagre og fortsett"
    ></app-ff-button>
  </form>
}

@if (resetToken.resetCompleted) {
  <div (keyup.enter)="loginStep()" data-cy="info-reset-success">
    <div class="well well--padded">Passordet ditt ble endret! Du kan nå bruke ditt nye passord for å logge inn.</div>
    <app-ff-button
      (clicked)="loginStep()"
      [processing]="processing"
      class="standalone"
      data-cy="button-login"
      label="Innlogging"
    ></app-ff-button>
  </div>
}

<app-feedback-messages #feedbackMessagesRef [messages]="errorMessages"></app-feedback-messages>

<app-login-form-alternative-links
  (redirect)="redirectTo($event)"
  [credentials]="loginSession.credentials"
  [links]="linkAlternatives"
  [queryParamsOverride]="loginSession.resetPasswordQueryParamsEntity.getQueryParamsOverride()"
  [oauthAuthorizeRedirectQueryParams]="oauthAuthorizeRedirectQueryParams"
></app-login-form-alternative-links>
