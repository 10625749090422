<div class='user-info-wrapper'>
  <h1 class='ff-h1'>
    Er dette riktig?
  </h1>
  <span class='subtitle'>
    Vi trenger å vite at opplysningene dine er riktige. Sjekk at dette stemmer før du går videre.
  </span>
  @if (debugSections.namesAndAddress || (pendingInformationUpdates().user.displayAllowed && pendingInformationUpdates().address.displayAllowed)) {
    <ff-ngx-name-and-address-card
      [form]='namesAndAddressForm'
      [countries]='countries()'
      [postalAreaLookup]='postalCodeLookup'
      [processing]="nameAndAddressFormProcessors() > 0"
      (formSubmitted)='namesAndAddressFormSubmitted($event)'
    ></ff-ngx-name-and-address-card>
  }

  @if (debugSections.namesAndAddress || (pendingInformationUpdates().user.displayAllowed && !pendingInformationUpdates().address.displayAllowed)) {
    <ff-ngx-card>
      <h2 class='ff-h2'>Navn</h2>
      @if (!namesForm.disabled) {
        <ff-ngx-name-input
          [form]='namesForm'
        />
        <div class='buttons buttons--inline'>
          <button
            ffNgxButton
            size='medium'
            type='button'
            (click)='namesFormSubmitted()'
          >
            Lagre
          </button>
          <button
            ffNgxButton
            size='medium'
            color='secondary'
            type='button'
            [disabled]='namesForm.invalid' (click)='namesForm.disable()'
          >
            Avbryt
          </button>
        </div>
      } @else {
        <p class='names'>{{ names().firstName }} {{ names().lastName }}</p>
        <button ffNgxButton (click)='namesForm.enable()' size='small' color='sand'>Rediger</button>
      }
    </ff-ngx-card>
  }

  @if (debugSections.bankAccountNumber || pendingInformationUpdates().employmentInfo?.displayAllowed) {
    <ff-ngx-bank-account-number-form
      [putBankAccountNumber]='putBankAccountNumber'
      [bankAccountNumber$]='bankAccountNumber$'
    />
  }

  @if (debugSections.phoneNumbers || pendingInformationUpdates().phone.displayAllowed) {
    <ff-ngx-phone-number-card
      [form]='phoneNumberCardForm'
      [countryCodes]='countryCodes()'
      [phoneNumbers]='phoneNumbers()'
      [postPhoneNumberVerification]='postPhoneNumberVerification'
      [deletePhoneNumber]='deletePhoneNumber'
      [postRequestPhoneNumberVerification]='requestSendVerificationSms'
      [setPrimaryPhoneNumber]='setPrimaryPhoneNumber'
      [postNewPhoneNumber]='submitNewPhoneNumber'
    >
      @if (phoneNumberCardForm.controls.phoneNumber.hasError('required')) {
        <span>Feltet er påkrevd</span>
      } @else if (phoneNumberCardForm.controls.phoneNumber.hasError('phoneNumberUnique')) {
        <span>Dette telefonnummeret finnes allerede i listen</span>
      } @else if (phoneNumberCardForm.controls.phoneNumber.hasError('invalid')) {
        <span>Dette ser ikke ut som et gyldig telefonnummer</span>
      } @else if (phoneNumberCardForm.controls.phoneNumber.hasError('invalid_country_code')) {
        <span>Dette ser ikke ut som et gyldig telefonnummer</span>
      } @else if (phoneNumberCardForm.controls.phoneNumber.hasError('invalid_length')) {
        <span>Dette ser ikke ut som et gyldig telefonnummer</span>
      } @else if (phoneNumberCardForm.controls.phoneNumber.hasError('invalid_type')) {
        <span>Dette ser ikke ut som et gyldig telefonnummer</span>
      } @else if (phoneNumberCardForm.controls.phoneNumber.hasError('too_short')) {
        <span>Dette ser ikke ut som et gyldig telefonnummer</span>
      } @else if (phoneNumberCardForm.controls.phoneNumber.hasError('too_long')) {
        <span>Dette ser ikke ut som et gyldig telefonnummer</span>
      }
    </ff-ngx-phone-number-card>
  }

  @if (debugSections.emailAddresses || pendingInformationUpdates().email.displayAllowed) {

    <ff-ngx-email-address-card
      [form]='emailAddressCardForm'
      [emailAddresses]='emailAddresses()'
      [postNewEmailAddress]='submitNewEmailAddress'
      [deleteEmailAddress]='deleteEmailAddress'
      [postRequestEmailAddressVerification]='requestEmailAddressVerification'
      [setPrimaryEmailAddress]='setPrimaryEmailAddress'
    >
      @if (emailAddressCardForm.controls.emailAddress.hasError('required')) {
        <span>Feltet er påkrevd</span>
      } @else if (emailAddressCardForm.controls.emailAddress.hasError('email') ||
      emailAddressCardForm.controls.emailAddress.hasError('invalid_email_address_error') ||
      emailAddressCardForm.controls.emailAddress.hasError('invalid_dns_error') ||
      emailAddressCardForm.controls.emailAddress.hasError('invalid_syntax_error') ||
      emailAddressCardForm.controls.emailAddress.hasError('invalid_7_bit_local_part_error')) {
        <span>Dette ser ikke ut som en gyldig e-postadresse</span>
      }
    </ff-ngx-email-address-card>
  }

  @if (warnings$ | async; as warnings) {
    <ff-ngx-card>
      <div class='warnings'>
        <ff-ngx-icon name='24x24-thick/form-error' [size]='24' />
        <ul>
          @for (warning of warnings; track warning) {
            <li>
              {{ warning }}
            </li>
          }
        </ul>
      </div>
    </ff-ngx-card>
  }

  <button
    ff-ngx-button
    color='primary'
    (click)='confirm()'
    [processing]='processing'
    [disabled]='false'
    role='button'
  >
    Bekreft
  </button>

  <button ff-ngx-button color='secondary' (click)='logout()' tabindex='0' role='button'>Avbryt</button>
</div>
