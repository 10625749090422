import { alias, serializable } from 'serializr';

export class LoginPhone {
  @serializable(alias('countryCode'))
  private _countryCode?: string;

  @serializable(alias('number'))
  private _number: string;

  set countryCode(code: string) {
    this._countryCode = code;
  }

  get countryCode(): string {
    return this._countryCode;
  }

  get number(): string {
    return this._number;
  }
  set number(value: string) {
    this._number = value;
  }

  get fullPhoneNumber(): string {
    if (this._countryCode) {
      return '+' + this._countryCode + this._number;
    } else {
      return this._number;
    }
  }
}
