/**
 *
 */
export class ResetPasswordTokenEntity {
  private _checked: boolean = false;
  private _resetCompleted: boolean = false;
  private _valid: boolean = false;

  get checked(): boolean {
    return this._checked;
  }

  set checked(value: boolean) {
    this._checked = value;
  }

  get resetCompleted(): boolean {
    return this._resetCompleted;
  }

  set resetCompleted(value: boolean) {
    this._resetCompleted = value;
  }

  get valid(): boolean {
    return this._valid;
  }

  set valid(value: boolean) {
    this._valid = value;
  }
}
