/**
 *
 */
export enum LoginFormEntries {
  FORGOTTEN_PASSWORD = 'FORGOTTEN_PASSWORD',
  LOGIN_OPTIONS = 'LOGIN_OPTIONS',
  OTP = 'OTP',
  PASSWORD = 'PASSWORD',
  RESET_PASSWORD = 'RESET_PASSWORD',
  SSN_MOBILE = 'SSN_MOBILE',
}
