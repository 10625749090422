import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { User } from '@core/entities/user/user.entity';
import { EmailService } from '@core/services/user/email.service';
import { Subject } from 'rxjs';
import { concatMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
})
export class EmailVerificationComponent implements OnDestroy, OnInit {
  error: boolean = false;
  errorDetail: string = null;
  processing: boolean = true;
  success: boolean = false;

  private readonly _onDestroy$ = new Subject<void>();

  constructor(private _activatedRoute: ActivatedRoute, private _emailService: EmailService) {}

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngOnInit() {
    this._activatedRoute.data
      .pipe(
        concatMap((data: { selfUser: User | null }) => {
          return this._activatedRoute.queryParams.pipe(
            concatMap((params: Params) => {
              return this._emailService.verifyEmail(
                params['uuid'],
                params['code'],
                data.selfUser ? data.selfUser.uuid : null,
              );
            }),
          );
        }),
        takeUntil(this._onDestroy$),
      )
      .subscribe(
        () => {
          this.processing = false;
          this.success = true;
        },
        (response: { error: { error: string } }) => {
          if (response.error.error === 'ERROR_ALREADY_VERIFIED') {
            this.success = true;
          } else {
            this.error = true;
            this.errorDetail = EmailVerificationErrorEnum['API_' + response.error.error];
          }
          this.processing = false;
        },
      );
  }
}

enum EmailVerificationErrorEnum {
  API_INVALID_LINK = 'Ugyldig lenke',
  API_ERROR_ALREADY_VERIFIED = 'E-posten er allerede blitt bekreftet',
  API_ERROR_NO_VERIFICATION_SECRET = 'Engangskode mangler',
  API_ERROR_TOTP_CODE_INVALID = 'Ugyldig engangskode – kanskje lenken har utløpt',
  API_NOT_FOUND = 'Fant ingen e-post som matcher referansen i lenken – prøv å be om bekreftelse på ny',
  API_UNKNOWN_ERROR = 'Ukjent feil',
}
