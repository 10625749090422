@if (item.iconPath) {
  <app-svg
    [class.active]="item.active"
    [heightPx]="iconSize"
    [svgPath]="item.iconPath"
    [widthPx]="iconSize"
    role="presentation"
  ></app-svg>
}

{{ item.label }}
