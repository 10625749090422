import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject } from '@angular/core';
import { LoginFormAlternativeLinkEntity } from '@core/entities/login/login-form-alternative-link.entity';
import { LoginFormRedirect } from '@core/entities/login/login-form-redirect.entity';
import { LoginProviderEntity } from '@core/entities/login/login-provider.entity';
import { LoginFormEntries } from '@core/enums/login-form-entries.enum';
import { ArrayHelper } from '@core/helpers/array.helper';
import { NavigationHelper } from '@core/helpers/navigation.helper';
import { WindowRefHelper } from '@core/helpers/window-ref.helper';
import { AuthenticationService } from '@core/services/authentication/authentication.service';
import { BaseLoginFormComponent } from '@shared/components/login-forms/base-login-form.component';
import { tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SharedModule } from '@shared/shared.module';
import {
  LoginFormAlternativeLinksComponent
} from '@shared/components/login-forms/login-form-alternative-links/login-form-alternative-links.component';

@Component({
  selector: 'app-login-options-form',
  templateUrl: './login-options-form.component.html',
  styleUrls: ['./login-options-form.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
    LoginFormAlternativeLinksComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginOptionsFormComponent extends BaseLoginFormComponent {
  #authenticationService = inject(AuthenticationService);
  #cdr = inject(ChangeDetectorRef);
  #destroyRef = inject(DestroyRef);

  clickedProvider: LoginProviderEntity;
  formEntries = LoginFormEntries;

  readonly linkAlternatives = new LoginFormAlternativeLinkEntity({ help: true });

  get providersOrdered(): LoginProviderEntity[] {
    return this.loginSession.availableLoginProviders.sort(ArrayHelper.fieldSorter(['order', 'name']));
  }

  nextStep(loginProvider: LoginProviderEntity): void {
    this.clickedProvider = loginProvider;
    this.processing = true;

    this.#cdr.detectChanges();

    if (loginProvider.type === LoginProviderTypesEnum.OPENID_CONNECT) {
      this._createOpenIdSession(loginProvider);
    } else {
      this._redirect(loginProvider);
    }
  }

  private _createOpenIdSession(loginProvider: LoginProviderEntity): void {
    this.#authenticationService
      .oidcCreateSession(
        loginProvider.uuid,
        WindowRefHelper.getNativeWindow().location.toString(),
        this.oauthAuthorizeRedirectQueryParams?.clientAuthAttemptUuid
      )
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        tap((authorizeUrl: string) => {
          NavigationHelper.redirect(authorizeUrl);
        })
      )
      .subscribe();
  }

  private _redirect(loginProvider: LoginProviderEntity): void {
    const redirect = new LoginFormRedirect();
    redirect.loginSession = this.loginSession;
    redirect.loginSession.selectedLoginProvider = loginProvider;
    redirect.component = this.formEntries[loginProvider.type];
    this.redirectTo(redirect);
  }
}

enum LoginProviderTypesEnum {
  OPENID_CONNECT = 'OPENID_CONNECT',
}
