import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss'],
})
export class PublicComponent implements OnInit {
  notOnDocumentationPage: boolean;

  /**
   * @param _router
   */
  constructor(private _router: Router) {}

  ngOnInit(): void {
    this.notOnDocumentationPage = this._router.url.split('/')[1] !== 'documentation';

    // Set active item on navigation
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap((n: NavigationEnd) => {
          this.notOnDocumentationPage = n.url.split('/')[1] !== 'documentation';
        }),
      )
      .subscribe();
  }
}
