import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ComponentRef,
  DestroyRef,
  inject,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ClientEntity } from '@core/entities/client/client.entity';
import { PendingInformationUpdates } from '@core/entities/login/pending-information-updates.entity';
import { ConsentStatusEntity } from '@core/entities/oauth/consent-status.entity';
import {
  OauthAuthorizeRedirectQueryParamsEntity,
} from '@core/entities/query-params/oauth-authorize-redirect-query-params.entity';
import { User } from '@core/entities/user/user.entity';
import { OauthAuthorizeRedirectErrorsEnum } from '@core/enums/oauth-authorize-redirect-errors.enum';
import { NavigationHelper } from '@core/helpers/navigation.helper';
import { AuthenticationService } from '@core/services/authentication/authentication.service';
import { OauthAuthorizationService } from '@core/services/authorization/oauth-authorization.service';
import { LogService } from '@core/services/log/log.service';
import { UserService } from '@core/services/user/user.service';
import { ErrorPageErrorsEnum, FfErrorPageComponent } from '@shared/components/ff-error-page/ff-error-page.component';
import { InformAndProceedComponent } from '@shared/components/inform-and-proceed/inform-and-proceed.component';
import { LoginFormsComponent } from '@shared/components/login-forms/login-forms.component';
import { OauthConsentResponseEntity } from '@shared/components/oauth-consent/oauth-consent-response.entity';
import { OauthConsentComponent } from '@shared/components/oauth-consent/oauth-consent.component';
import { SetUserPasswordComponent } from '@shared/components/set-user-password/set-user-password.component';
import { UserInfoUpdateComponent } from '@shared/components/user-info-update/user-info-update.component';
import { merge, of, throwError } from 'rxjs';
import { catchError, concatMap, retry, take, tap } from 'rxjs/operators';
import { deserialize } from 'serializr';
import { NgComponentOutlet } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-openid-error-handler',
  templateUrl: './openid-error-handler.component.html',
  styleUrls: ['./openid-error-handler.component.scss'],
  standalone: true,
  imports: [
    NgComponentOutlet,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpenidErrorHandlerComponent implements AfterViewInit {
  #cdr = inject(ChangeDetectorRef);
  #destroyRef = inject(DestroyRef);

  @ViewChild('viewContainer', { read: ViewContainerRef })
  viewContainerRef: ViewContainerRef;

  clientInfo: ClientEntity;

  private _oauthAuthorizeRedirect: OauthAuthorizeRedirectQueryParamsEntity;

  constructor(
    private _authenticationService: AuthenticationService,
    private _activatedRoute: ActivatedRoute,
    private _logService: LogService,
    private _oauthAuthorizationService: OauthAuthorizationService,
    private _router: Router,
    private _userService: UserService,
  ) {}

  ngAfterViewInit(): void {
    const data$ = this._activatedRoute.data.pipe(
      tap((data) => {
        this.clientInfo = data['clientInfo'];
      }),
    );

    const params$ = this._activatedRoute.queryParams.pipe(
      tap((params: Params) => {
        this._oauthAuthorizeRedirect = deserialize(OauthAuthorizeRedirectQueryParamsEntity, params);
        if (this._oauthAuthorizeRedirect.isOidcCallback) {
          this._loginCallback();
        } else {
          this._handleAuthorizationRequest();
        }
      }),
    );

    merge(data$, params$).pipe(takeUntilDestroyed(this.#destroyRef)).subscribe();
  }

  private _checkOauthConsent(): void {
    const requestedScopeNames = new URL(decodeURIComponent(this._oauthAuthorizeRedirect.redir)).searchParams
      .get('scope')
      .split(' ');

    this._oauthAuthorizationService
      .getConsentStatus(this._oauthAuthorizeRedirect.clientId, requestedScopeNames)
      .pipe(
        tap((consentStatus: ConsentStatusEntity) => {
          if (consentStatus.consentRequired) {
            this._showConsentForm(consentStatus);
          } else {
            this._checkPendingInfoUpdates();
          }
        }),
        take(1),
        retry(1),
        catchError((e: HttpErrorResponse) => {
          this._showError();
          return throwError(() => e);
        }),
      )
      .subscribe();
  }

  private _handleAuthorizationRequest(): void {
    switch (this._oauthAuthorizeRedirect.internalErrorCode) {
      case OauthAuthorizeRedirectErrorsEnum.CLIENT_NOT_FOUND:
      case OauthAuthorizeRedirectErrorsEnum.REDIRECT_URI_DID_NOT_MATCH_CLIENT:
      case OauthAuthorizeRedirectErrorsEnum.INVALID_REDIRECT_URI:
      case OauthAuthorizeRedirectErrorsEnum.MISSING_CLIENT_ID:
      case OauthAuthorizeRedirectErrorsEnum.MISSING_REDIRECT_URI:
      case OauthAuthorizeRedirectErrorsEnum.DEV_CLIENT_NO_ACCESS:
      case OauthAuthorizeRedirectErrorsEnum.SCOPE_INVALID:
        // TODO: Have the API redirect
        this._showError(ErrorPageErrorsEnum.LOGIN);
        break;
      case OauthAuthorizeRedirectErrorsEnum.LOGIN_REQUIRED:
      case OauthAuthorizeRedirectErrorsEnum.LOGIN_REQUIRED_MAX_AUTH_TIME_EXCEEDED:
        this._showLoginForm();
        break;
      case OauthAuthorizeRedirectErrorsEnum.USER_INFORMATION_REQUIRED:
        this._checkPendingInfoUpdates();
        break;
      case OauthAuthorizeRedirectErrorsEnum.CONSENT_REQUIRED:
        this._checkOauthConsent();
        break;
      default:
        let errMsg = '';
        if (!this._oauthAuthorizeRedirect.internalErrorCode) {
          errMsg = 'Falsy internalErrorCode. RedirectObj: ' + JSON.stringify(this._oauthAuthorizeRedirect);
        } else {
          errMsg =
            'internalErrorCode «' +
            this._oauthAuthorizeRedirect.internalErrorCode +
            '» not handled by openid-error-handler.';
        }
        this._logService.logException(new Error(errMsg));

        this._showError();
        break;
    }
  }

  private _checkPendingInfoUpdates(): void {
    this._userService
      .getPendingInformationUpdates(this._oauthAuthorizeRedirect.clientId)
      .pipe(
        tap((pending: PendingInformationUpdates) => {
          if (pending.verificationRequired || pending.hasPendingUpdates()) {
            if (pending.password.required) {
              this._showUserPasswordForm(pending);
            } else {
              this._showUserInfoForm(pending);
            }
          } else {
            this._redirect();
          }
        }),
        take(1),
        retry(1),
        catchError((e) => {
          this._showError();
          return throwError(() => e);
        }),
      )
      .subscribe();
  }

  private _loginCallback(): void {
    this._checkOauthConsent();
  }

  /**
   * If the requested redirect_uri param has a non http scheme (i.e. deep links like 'myapp://oauth'),
   * we don't know if we're able to automatically redirect.
   * In those cases, we show the <InformAndProceedComponent> to supply the user with a button to initiate the redirect.
   */
  private _redirect(): void {
    const redir = new URL(this._oauthAuthorizeRedirect.redir);
    const redirect_uri = redir.searchParams.get('redirect_uri');
    if (NavigationHelper.hasHypertextTransferProtocolScheme(redirect_uri)) {
      NavigationHelper.redirect(this._oauthAuthorizeRedirect.redir);
    } else {
      const component = this.#loadComponent<InformAndProceedComponent>(InformAndProceedComponent);

      if (this.clientInfo && this.clientInfo.name) {
        component.instance.clientName = this.clientInfo.name;
      }

      component.instance.proceedUrl = decodeURIComponent(this._oauthAuthorizeRedirect.redir);
      this.#cdr.detectChanges();
    }
  }

  private _showConsentForm(consentStatus: ConsentStatusEntity) {
    const component = this.#loadComponent<OauthConsentComponent>(OauthConsentComponent);
    component.instance.clientInfo = this.clientInfo;
    component.instance.consentStatus = consentStatus;

    component.instance.authorizeCallback
      .pipe(
        tap((response: OauthConsentResponseEntity) => {
          if (response.consentGiven) {
            this._oauthAuthorizationService
              .setConsent(this._oauthAuthorizeRedirect.clientId, response)
              .pipe(
                tap(() => {
                  this._checkPendingInfoUpdates();
                }),
                take(1),
                retry(1),
                catchError((e) => {
                  this._showError();
                  return throwError(() => e);
                }),
              )
              .subscribe();
          } else {
            // Consent not given, "cancel" was clicked
            void this._router.navigate(['error'], { queryParams: { error_type: ErrorPageErrorsEnum.CONSENT_MISSING } });
          }
        }),
        catchError((e) => {
          this._showError();
          return throwError(() => e);
        }),
      )
      .subscribe();

    this.#cdr.detectChanges();
  }

  private _showError(errorType = ErrorPageErrorsEnum.UNKNOWN) {
    const component = this.#loadComponent<FfErrorPageComponent>(FfErrorPageComponent);
    component.instance.errorType = errorType;
    this.#cdr.detectChanges();
  }

  private _showLoginForm(): void {
    const component = this.#loadComponent<LoginFormsComponent>(LoginFormsComponent);

    component.instance.clientInfo = this.clientInfo;
    component.instance.oauthAuthorizeRedirectQueryParams = this._oauthAuthorizeRedirect;

    component.instance.loggedInCallback
      .pipe(
        tap(() => {
          this._loginCallback();
        }),
        take(1),
      )
      .subscribe();
    this.#cdr.detectChanges();
  }

  private _showUserInfoForm(pending: PendingInformationUpdates): void {
    this._userService
      .getSelf()
      .pipe(
        tap((user: User) => {
          const component = this.#loadComponent<UserInfoUpdateComponent>(UserInfoUpdateComponent);
          component.instance.user.set(user);
          component.instance.pendingInformationUpdates.set(pending);

          component.instance.proceedCallback
            .pipe(
              concatMap(() => {
                if (pending.verificationRequired) {
                  return this._authenticationService.userInfoUpdated().pipe(
                    tap((infoVerified: boolean) => {
                      if (infoVerified) {
                        this._redirect();
                      } else {
                        this._showError();
                      }
                    }),
                  );
                } else {
                  this._redirect();
                  return of(null);
                }
              }),
              take(1),
              retry(1),
              catchError((e) => {
                this._showError();
                return throwError(() => e);
              }),
            )
            .subscribe();
          this.#cdr.detectChanges();
        }),
        take(1),
      )
      .subscribe();
  }

  private _showUserPasswordForm(pending: PendingInformationUpdates): void {
    this._userService
      .getSelf()
      .pipe(
        tap((user: User) => {
          const component = this.#loadComponent<SetUserPasswordComponent>(SetUserPasswordComponent);
          component.instance.user = user;
          component.instance.pendingInfoUpdates = pending;

          component.instance.proceedCallback
            .pipe(
              tap(() => {
                if (pending.verificationRequired) {
                  this._showUserInfoForm(pending);
                } else {
                  this._redirect();
                }
              }),
              take(1),
            )
            .subscribe();
          this.#cdr.detectChanges();
        }),
        take(1),
      )
      .subscribe();
  }

  #loadComponent<T>(form: any): ComponentRef<T> {
    this.viewContainerRef.clear();
    return this.viewContainerRef.createComponent<T>(form);
  }
}
