<h1>
  <span> Logg inn i ID </span>
  <span class="subtitle">Vi trenger fødselsnummeret ditt for å identifisere deg som bruker.</span>
</h1>

<form
  (keyup.enter)="nextStep()"
  [class.submitted]="submitted"
  [formGroup]="loginForm"
  class="form"
  data-cy="form-credentials"
  >
  @if (loginForm.controls.ssn) {
    <div class="form-group">
      <div class="form-control">
        <label for="ssn">Fødselsnummer</label>
        <input
          #focusRef
          (input)="showErrorsIfSubmitted()"
          [formControl]="loginForm.controls.ssn"
          [readOnly]="processing ? true : null"
          [maxLength]="11"
          autocomplete="ssn"
          data-cy="input-ssn"
          name="ssn"
          id="ssn"
          inputmode="numeric"
          placeholder="Elleve siffer"
          type="text"
          />
      </div>
    </div>
  }

  <div class="form-group">
    <div class="form-control">
      <app-phone-form
        [countryCodes$]="countryCodes$"
        [phoneFormGroup]="loginForm.controls.phone"
        [showErrors]="false"
      ></app-phone-form>
    </div>
  </div>

  <app-ff-button
    (clicked)="nextStep()"
    [processing]="processing"
    class="standalone"
    data-cy="button-next"
    iconPath="icons/arrow_right.svg"
  ></app-ff-button>
</form>

<app-feedback-messages #feedbackMessagesRef [messages]="errorMessages"></app-feedback-messages>

<app-login-form-alternative-links
  (redirect)="redirectTo($event)"
  [credentials]="loginSession.credentials"
  [links]="linkAlternatives"
  [oauthAuthorizeRedirectQueryParams]="oauthAuthorizeRedirectQueryParams"
></app-login-form-alternative-links>
