import { QueryParamResetInterface } from '@core/interfaces/query-param-reset.interface';
import { QueryParamEnum, QueryParamInterface } from '@core/interfaces/query-param.interface';
import { alias, serializable } from 'serializr';

export class ResetPasswordQueryParamsEntity implements QueryParamResetInterface {
  @serializable(alias('action'))
  private _action: string;

  @serializable(alias('code'))
  private _code: string;

  @serializable(alias('uuid'))
  private _id: string;

  get action(): string {
    return this._action;
  }

  get code(): string {
    return this._code;
  }

  get id(): string {
    return this._id;
  }

  isResetPasswordCallback(): boolean {
    return this._action === 'reset-password';
  }

  getQueryParamsOverride(): QueryParamInterface {
    return {
      strip: [QueryParamEnum.ACTION, QueryParamEnum.CODE, QueryParamEnum.UUID],
    };
  }
}
