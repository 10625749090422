import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  ComponentRef,
  DestroyRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientEntity } from '@core/entities/client/client.entity';
import { LoginCredentials } from '@core/entities/login/login-credentials.entity';
import { LoginFormRedirect } from '@core/entities/login/login-form-redirect.entity';
import { LoginProviderEntity } from '@core/entities/login/login-provider.entity';
import { LoginSession } from '@core/entities/login/login-session.entity';
import { ResetPasswordQueryParamsEntity } from '@core/entities/query-params/reset-password-query-params.entity';
import { LoginResponse } from '@core/entities/response/login-response.entity';
import { LoginFormEntries } from '@core/enums/login-form-entries.enum';
import { AuthenticationService } from '@core/services/authentication/authentication.service';
import { BaseLoginFormComponent } from '@shared/components/login-forms/base-login-form.component';
import { CredentialsFormComponent } from '@shared/components/login-forms/credentials-form/credentials-form.component';
import {
  ForgottenPasswordFormComponent,
} from '@shared/components/login-forms/forgotten-password-form/forgotten-password-form.component';
import {
  LoginOptionsFormComponent,
} from '@shared/components/login-forms/login-options-form/login-options-form.component';
import { OtpFormComponent } from '@shared/components/login-forms/otp-form/otp-form.component';
import { PasswordFormComponent } from '@shared/components/login-forms/password-form/password-form.component';
import {
  PasswordResetFormComponent,
} from '@shared/components/login-forms/password-reset-form/password-reset-form.component';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';
import { deserialize } from 'serializr';
import {
  OauthAuthorizeRedirectQueryParamsEntity,
} from '@core/entities/query-params/oauth-authorize-redirect-query-params.entity';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StringHelper } from '@core/helpers/string.helper';

@Component({
  selector: "app-login-forms",
  templateUrl: "./login-forms.component.html",
  styleUrls: ["./login-forms.component.scss"],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginFormsComponent implements AfterViewInit {
  #authenticationService = inject(AuthenticationService);
  #activatedRoute = inject(ActivatedRoute);
  #destroyRef = inject(DestroyRef);
  #cdr = inject(ChangeDetectorRef);

  @Input()
  clientInfo: ClientEntity;

  @Input()
  oauthAuthorizeRedirectQueryParams?: OauthAuthorizeRedirectQueryParamsEntity;

  @Output()
  loggedInCallback: EventEmitter<LoginResponse> = new EventEmitter();

  @HostBinding("class")
  class: string;

  @ViewChild('viewContainer', { read: ViewContainerRef })
  viewContainerRef: ViewContainerRef;

  private _loginSession: LoginSession;
  private _componentInstances = {
    [LoginFormEntries.FORGOTTEN_PASSWORD]: ForgottenPasswordFormComponent,
    [LoginFormEntries.LOGIN_OPTIONS]: LoginOptionsFormComponent,
    [LoginFormEntries.OTP]: OtpFormComponent,
    [LoginFormEntries.PASSWORD]: PasswordFormComponent,
    [LoginFormEntries.RESET_PASSWORD]: PasswordResetFormComponent,
    [LoginFormEntries.SSN_MOBILE]: CredentialsFormComponent,
  };

  ngAfterViewInit(): void {
    this.resetLoginSession();

    this._getLoginProviders()
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        tap(() => {
          this.figureOutWhereToStart();
        })
      )
      .subscribe();
  }

  private figureOutWhereToStart(): void {
    const queryParams = this.#activatedRoute.snapshot.queryParams;

    const redirect = new LoginFormRedirect();
    redirect.loginSession = this._loginSession;

    const resetPasswordQueryParamsEntity = deserialize(
      ResetPasswordQueryParamsEntity,
      queryParams
    );

    if (resetPasswordQueryParamsEntity.isResetPasswordCallback()) {
      redirect.loginSession.isResetPasswordCallback = true;
      redirect.loginSession.resetPasswordQueryParamsEntity =
        resetPasswordQueryParamsEntity;
      redirect.component = LoginFormEntries.RESET_PASSWORD;
    } else {
      redirect.component = LoginFormEntries.LOGIN_OPTIONS;
    }

    // TODO: Clean url?
    this._loadComponent(redirect);
  }

  /**
   * TODO: Make login session available inside forms instead of passing through redirect entity?
   */
  private resetLoginSession(): void {
    this._loginSession = new LoginSession(
      this.clientInfo,
      new LoginCredentials(),
      []
    );
  }

  /**
   * TODO: Add loading screen while fetching
   * TODO: Handle errors
   * TODO: Resolve on app bootstrap instead?
   */
  private _getLoginProviders(): Observable<boolean> {
    return this.#authenticationService
      .getLoginProviders(
        this.oauthAuthorizeRedirectQueryParams?.clientAuthAttemptUuid
      )
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        map((loginProviders: LoginProviderEntity[]) => {
          this._loginSession.availableLoginProviders = loginProviders;
          return true;
        })
      );
  }

  private _loadComponent(redirect: LoginFormRedirect): void {
    this.viewContainerRef.clear();
    const component = this.viewContainerRef.createComponent<BaseLoginFormComponent>(this._componentInstances[redirect.component.toString()]);

    this.class = StringHelper.kebabCase(redirect.component);
    component.instance.loginSession = this._loginSession;
    component.instance.oauthAuthorizeRedirectQueryParams =
      this.oauthAuthorizeRedirectQueryParams;

    this._subscribeToComponentOutputs(component);
    this.#cdr.detectChanges();
  }

  private _subscribeToComponentOutputs(
    component: ComponentRef<BaseLoginFormComponent>
  ): void {
    // Login form redirect
    (component.instance as BaseLoginFormComponent).showComponent
      .pipe(
        first(),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe((redirect: LoginFormRedirect) => {
        if (redirect.loginSession) {
          this._loginSession = redirect.loginSession;
        }

        // If going to start, reset LoginSession
        if (redirect.component === LoginFormEntries.LOGIN_OPTIONS) {
          this.resetLoginSession();
          this._getLoginProviders()
            .pipe(
              takeUntilDestroyed(this.#destroyRef),
              tap(() => {
                this._loadComponent(redirect);
                this.#cdr.detectChanges();
              })
            )
            .subscribe();
        } else {
          this._loadComponent(redirect);
          this.#cdr.detectChanges();
        }
      });

    // User logged in
    (component.instance as BaseLoginFormComponent).loginCallback
      .pipe(
        first(),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe((loginResponse: LoginResponse) => {
        this.loggedInCallback.emit(loginResponse);
      });
  }
}
