<div class="container">
  <div class="content">
    <h1>Logget ut</h1>
    <p class="intro">Du er nå logget ut.</p>

    <app-ff-button
      (clicked)="redirect(defaultLogoutUrl)"
      class="standalone padded"
      color="red"
      iconPath="icons/arrow_right.svg"
    ></app-ff-button>
  </div>
</div>
