import {
  ClientErrorResponse,
  ClientErrorResponseSchema,
} from '@core/entities/http-responses/client-error-response.entity';

export class NotFoundResponse extends ClientErrorResponse {}

export const NotFoundResponseSchema = {
  factory: () => new NotFoundResponse(),
  extends: ClientErrorResponseSchema,
  props: {},
};
