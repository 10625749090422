import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UnauthorizedResponse } from '@core/entities/http-responses/unauthorized-response.entity';
import { LoginFormAlternativeLinkEntity } from '@core/entities/login/login-form-alternative-link.entity';
import { LoginResponse } from '@core/entities/response/login-response.entity';
import { ReactiveFormsHelper } from '@core/helpers/reactive-forms.helper';
import { AuthenticationService } from '@core/services/authentication/authentication.service';
import { ErrorPageErrorsEnum } from '@shared/components/ff-error-page/ff-error-page.component';
import { BaseLoginFormComponent } from '@shared/components/login-forms/base-login-form.component';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SharedModule } from '@shared/shared.module';
import {
  LoginFormAlternativeLinksComponent,
} from '@shared/components/login-forms/login-form-alternative-links/login-form-alternative-links.component';

@Component({
  selector: 'app-password-form',
  templateUrl: './password-form.component.html',
  styleUrls: ['./password-form.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SharedModule,
    LoginFormAlternativeLinksComponent,
  ],
})
export class PasswordFormComponent extends BaseLoginFormComponent implements OnInit {
  static readonly ERROR_GENERIC = 'error';
  static readonly ERROR_EXPIRED = 'expired';
  static readonly ERROR_INCORRECT = 'incorrect';
  static readonly ERROR_REQUIRED = 'required';

  #authenticationService = inject(AuthenticationService);
  #cdr = inject(ChangeDetectorRef);
  #destroyRef = inject(DestroyRef);
  #formBuilder = inject(UntypedFormBuilder);
  #router = inject(Router);

  readonly linkAlternatives = new LoginFormAlternativeLinkEntity({ forgotPassword: true, restart: true });
  formHelper = ReactiveFormsHelper;
  passwordForm: UntypedFormGroup;

  ngOnInit(): void {
    this.passwordForm = this.#formBuilder.group({
      password: [null, Validators.required],
    });
  }

  nextStep(): void {
    this.submitted = true;

    if (this.passwordForm.invalid) {
      this.showErrorsIfSubmitted();
      this.#cdr.detectChanges();
      return;
    }

    this.processing = true;

    this.#authenticationService
      .login(
        this.loginSession.credentials.otp,
        this.loginSession.credentials.entryToken,
        this.passwordForm.get('password').value,
      )
      .pipe(
        tap((loginResponse: LoginResponse) => {
          this.login(loginResponse);
        }),
        takeUntilDestroyed(this.#destroyRef),
        catchError((response: HttpErrorResponse) => {
          this.processing = false;
          this.passwordForm.markAsDirty();
          const passCtrl = this.passwordForm.get('password');
          passCtrl.markAsDirty();
          passCtrl.markAsTouched();
          this.#cdr.detectChanges();

          if (response.error.error === 'USER_DEACTIVATED') {
            void this.#router.navigate(['error'], {
              queryParams: { error_type: ErrorPageErrorsEnum.USER_DEACTIVATED },
            });

            return throwError(() => response);
          }

          if (response.error.error === 'INVALID_OTP') {
            passCtrl.setErrors({ [PasswordFormComponent.ERROR_EXPIRED]: true });
          }

          if (401 === response.status || response instanceof UnauthorizedResponse) {
            passCtrl.setErrors({ [PasswordFormComponent.ERROR_INCORRECT]: true });
          }

          // Generic error msg as catch-all, for 500 or other unhandled
          if (!passCtrl.errors || Object.values(passCtrl.errors).length < 1) {
            passCtrl.setErrors({ [PasswordFormComponent.ERROR_GENERIC]: true });
          }

          this.showErrorsIfSubmitted();
          this.#cdr.detectChanges();
          return throwError(() => response);
        }),
      )
      .subscribe();
  }

  /**
   * Use helper to generate strings explaining each field error constant
   */
  showErrorsIfSubmitted(): void {
    if (!this.submitted) {
      return;
    }

    this.setErrorMessages([]);
    this.#cdr.detectChanges();

    const passCtrl = this.passwordForm.get('password') as UntypedFormControl;

    const errors = ReactiveFormsHelper.composeErrorMessages([
      { ctrl: passCtrl, err: PasswordFormComponent.ERROR_GENERIC, msg: PasswordFormErrorsEnum.ERROR },
      { ctrl: passCtrl, err: PasswordFormComponent.ERROR_EXPIRED, msg: PasswordFormErrorsEnum.EXPIRED },
      { ctrl: passCtrl, err: PasswordFormComponent.ERROR_INCORRECT, msg: PasswordFormErrorsEnum.INCORRECT },
      { ctrl: passCtrl, err: PasswordFormComponent.ERROR_REQUIRED, msg: PasswordFormErrorsEnum.REQUIRED },
    ]);

    this.setErrorMessages(errors.errorMessages);
    this.#cdr.detectChanges();
  }
}

enum PasswordFormErrorsEnum {
  ERROR = 'Noe gikk galt. Vennligst prøv på ny eller kontakt Fagforbundet',
  EXPIRED = 'Det har gått for lang tid, du må nå starte innlogging på ny',
  INCORRECT = 'Passordet du oppga er feil',
  REQUIRED = 'Du må oppgi passord for å gå videre. Bruk ev. linken nedenfor om du har glemt passordet',
}
