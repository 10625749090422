import { Component } from '@angular/core';
import { ErrorPageErrorsEnum } from '@shared/components/ff-error-page/ff-error-page.component';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {
  error = ErrorPageErrorsEnum.PAGE_NOT_FOUND;
}
