<h1>
  Engangskode
  <span class="subtitle">Skriv inn koden vi sendte til {{ loginSession.credentials.phone.fullPhoneNumber }}</span>
</h1>

<form
  #formRef
  (keyup.enter)="nextStep()"
  [class.submitted]="submitted"
  [formGroup]="otpForm"
  class="form"
  data-cy="form-otp"
>
  <div class="form-group">
    <div class="form-control">
      <label for="otp">Kode fra SMS</label>
      <input
        #focusRef
        (input)="showErrorsIfSubmitted()"
        [formControl]="formHelper.getCtrl(otpForm, 'otp')"
        [maxLength]="6"
        [readOnly]="processing ? true : null"
        autocomplete="one-time-code"
        data-cy="input-otp"
        id="otp"
        inputmode="numeric"
        placeholder="6 siffer"
        type="text"
      />
    </div>
  </div>

  <app-ff-button
    (clicked)="nextStep()"
    [processing]="processing"
    class="standalone"
    data-cy="button-next"
    iconPath="icons/arrow_right.svg"
  ></app-ff-button>
</form>

<app-feedback-messages #feedbackMessagesRef [messages]="errorMessages"></app-feedback-messages>

<app-login-form-alternative-links
  (clicked)="clickedAlternative($event)"
  (redirect)="redirectTo($event)"
  [credentials]="loginSession.credentials"
  [links]="linkAlternatives"
  [oauthAuthorizeRedirectQueryParams]="oauthAuthorizeRedirectQueryParams"
></app-login-form-alternative-links>
