<h1>
  Glemt passord
  @if (!showForgotPasswordInstruction) {
    <span class="subtitle">
      Oppgi din e-postadresse for å få tilsendt nytt passord
    </span>
  }
</h1>

@if (!showForgotPasswordInstruction) {
  <form
    #formRef
    (keyup.enter)="nextStep()"
    [class.submitted]="submitted"
    [formGroup]="forgottenPasswordForm"
    class="form"
    data-cy="form-forgot-password"
    >
    <div class="form-group">
      <div class="form-control">
        <label for="email">E-post</label>
        <input
          #focusRef
          (input)="showErrorsIfSubmitted()"
          [formControl]="formHelper.getCtrl(forgottenPasswordForm, 'email')"
          [readOnly]="processing ? true : null"
          autocomplete="email"
          data-cy="input-email"
          id="email"
          inputmode="email"
          type="text"
          />
      </div>
    </div>
    <app-ff-button
      (clicked)="nextStep()"
      [processing]="processing"
      class="standalone"
      data-cy="button-next"
      iconPath="icons/arrow_right.svg"
    ></app-ff-button>
  </form>
}

@if (showForgotPasswordInstruction) {
  <div data-cy="info-instructions">
    <p>Vi har nå sendt deg en e-post med instruks for å sette nytt passord.</p>
    <p>Hvis den ikke dukker opp, pass på at du har tastet inn riktig e-post, og sjekk i samme slengen søppelpost.</p>
  </div>
}

<app-feedback-messages #feedbackMessagesRef [messages]="errorMessages"></app-feedback-messages>

<app-login-form-alternative-links
  (redirect)="redirectTo($event)"
  [credentials]="loginSession.credentials"
  [links]="linkAlternatives"
  [oauthAuthorizeRedirectQueryParams]="oauthAuthorizeRedirectQueryParams"
></app-login-form-alternative-links>
