import { alias, serializable } from 'serializr';

export class SsoDataQueryParamsEntity {
  @serializable(alias('app_id'))
  private _appId: string;

  @serializable(alias('return_to'))
  private _returnTo: string;

  get appId(): string {
    return this._appId;
  }

  get returnTo(): string {
    return this._returnTo;
  }
}
