import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { SsoDataQueryParamsEntity } from '@core/entities/query-params/sso-data-query-params.entity';
import { SsoDataResponse } from '@core/entities/response/sso-data-response.entity';
import { AuthenticationService } from '@core/services/authentication/authentication.service';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { deserialize } from 'serializr';

export const ssoDataResolver = (): ResolveFn<SsoDataResponse> => {
  return (activatedRouteSnapshot: ActivatedRouteSnapshot) => {
    const authenticationService = inject(AuthenticationService);

    const ssoDataParams = deserialize(SsoDataQueryParamsEntity, activatedRouteSnapshot.queryParams);

    return authenticationService.ssoAuthenticate(ssoDataParams.appId, ssoDataParams.returnTo).pipe(
      map((ssoData: SsoDataResponse) => {
        return ssoData;
      }),
      catchError((error: SsoDataResponse) => {
        return of(error);
      })
    );
  };
};
