import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginFormAlternativeLinkEntity } from '@core/entities/login/login-form-alternative-link.entity';
import { LoginFormRedirect } from '@core/entities/login/login-form-redirect.entity';
import { ResetPasswordTokenEntity } from '@core/entities/login/reset-password-token.entity';
import { ValidationViolationResponseEntity } from '@core/entities/response/validation-violation-response.entity';
import { LoginFormEntries } from '@core/enums/login-form-entries.enum';
import { ReactiveFormsHelper } from '@core/helpers/reactive-forms.helper';
import { FieldErrorMessageInterface } from '@core/interfaces/field-error-message.interface';
import { AuthenticationService } from '@core/services/authentication/authentication.service';
import { BaseLoginFormComponent } from '@shared/components/login-forms/base-login-form.component';
import { confirmPasswordGroupValidator } from '@shared/validators/confirm-password-group.validator';
import { throwError } from 'rxjs';
import { catchError, finalize, take, tap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SharedModule } from '@shared/shared.module';
import {
  LoginFormAlternativeLinksComponent,
} from '@shared/components/login-forms/login-form-alternative-links/login-form-alternative-links.component';

@Component({
  selector: 'app-password-reset-form',
  templateUrl: './password-reset-form.component.html',
  styleUrls: ['./password-reset-form.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SharedModule,
    LoginFormAlternativeLinksComponent,
  ],
})
export class PasswordResetFormComponent extends BaseLoginFormComponent implements OnInit {
  static readonly ERROR_NOT_MATCHING = 'not_matching';
  static readonly ERROR_REQUIRED = 'required';
  readonly linkAlternatives = new LoginFormAlternativeLinkEntity({ restart: true });
  apiResponseCtrl: UntypedFormControl; // A ctrl to hold any error responses from API and toggle form invalid
  confCtrl: UntypedFormControl;
  formHelper = ReactiveFormsHelper;
  passCtrl: UntypedFormControl;
  passwordResetForm: UntypedFormGroup;
  resetToken: ResetPasswordTokenEntity;
  #activatedRoute = inject(ActivatedRoute);
  #authenticationService = inject(AuthenticationService);
  #cdr = inject(ChangeDetectorRef);
  #destroyRef = inject(DestroyRef);
  #formBuilder = inject(UntypedFormBuilder);
  #location = inject(Location);
  #router = inject(Router);

  ngOnInit(): void {
    this._buildForm();

    this.processing = true;
    this.resetToken = new ResetPasswordTokenEntity();

    this.#cdr.detectChanges();

    this.#authenticationService
      .passwordResetTokenValidation(
        this.loginSession.resetPasswordQueryParamsEntity.code,
        this.loginSession.resetPasswordQueryParamsEntity.id,
      )
      .pipe(
        take(1),
        tap(() => {
          this.resetToken.checked = true;
          this.resetToken.valid = true;
          this.processing = false;
        }),
        catchError((response: HttpErrorResponse) => {
          this.processing = false;
          this.resetToken.checked = true;
          this.resetToken.valid = false;

          // Handle API responding the token is invalid. Example: 'TOPT_CODE_INVALID'
          this.apiResponseCtrl.setErrors({ [ApiErrors.INVALID_LINK]: true });
          this.showErrorsIfSubmitted();

          return throwError(() => response);
        }),
        finalize(() => {
          this.#cdr.detectChanges();
        }),
      )
      .subscribe();
  }

  loginStep(): void {
    // TODO: Include success message
    const redir = new LoginFormRedirect();
    redir.component = LoginFormEntries.LOGIN_OPTIONS;
    this.#router
      .navigate([], {
        relativeTo: this.#activatedRoute,
        queryParams: {},
      })
      .then(() => {
        this.redirectTo(redir);
      })
      .catch(() => {
      });
  }

  nextStep(): void {
    this.submitted = true;

    if (this.passwordResetForm.invalid) {
      this.showErrorsIfSubmitted();
      this.#cdr.detectChanges();
      return;
    }

    this.processing = true;
    this.#cdr.detectChanges();

    this.#authenticationService
      .setPassword(
        this.passCtrl.value,
        this.confCtrl.value,
        this.loginSession.resetPasswordQueryParamsEntity.id,
        this.loginSession.resetPasswordQueryParamsEntity.code,
      )
      .pipe(
        tap(() => {
          this.#location.replaceState('/?action=reset-password');
          this.resetToken.resetCompleted = true;
          this.processing = false;
          this.#cdr.detectChanges();
        }),
        catchError((response: HttpErrorResponse) => {
          this.processing = false;
          this.resetToken.checked = true;
          this.resetToken.valid = false;
          this.resetToken.resetCompleted = false;

          if (response.error.error === 'VALIDATION_ERROR' && response.error.violations) {
            this.resetToken.valid = true;
            response.error.violations.forEach((v: ValidationViolationResponseEntity) => {
              this.passCtrl.setErrors({ [v.errorName.toUpperCase()]: true });
            });
          }

          this.showErrorsIfSubmitted();
          this.#cdr.detectChanges();

          return throwError(() => response);
        }),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe();
  }

  /**
   * Use helper to generate strings explaining each field error constant
   */
  showErrorsIfSubmitted(): void {
    if (!this.submitted && !this.resetToken.checked) {
      return;
    }

    this.setErrorMessages([]);
    this.#cdr.detectChanges();

    const fields: FieldErrorMessageInterface[] = [
      { ctrl: this.apiResponseCtrl, err: ApiErrors.INVALID_LINK, msg: ResetFormErrors.API_INVALID_LINK },
      { ctrl: this.apiResponseCtrl, err: ApiErrors.INVALID_TYPE_ERROR, msg: ResetFormErrors.API_INVALID_TYPE_ERROR },
      { ctrl: this.apiResponseCtrl, err: ApiErrors.TOTP_CODE_INVALID, msg: ResetFormErrors.API_INVALID_LINK },
    ];

    if (this.submitted) {
      fields.push(
        { ctrl: this.passCtrl, err: ApiErrors.COMPROMISED_PASSWORD_ERROR, msg: ResetFormErrors.API_COMPROMISED },
        { ctrl: this.passCtrl, err: ApiErrors.IS_BLANK_ERROR, msg: ResetFormErrors.API_IS_BLANK_ERROR },
        { ctrl: this.passCtrl, err: ApiErrors.NOT_TRUE_ERROR, msg: ResetFormErrors.API_NOT_TRUE_ERROR },
        { ctrl: this.passCtrl, err: ApiErrors.TOO_SHORT_ERROR, msg: ResetFormErrors.API_TOO_SHORT_ERROR },
        { ctrl: this.passCtrl, err: PasswordResetFormComponent.ERROR_REQUIRED, msg: ResetFormErrors.REQUIRED },
        { ctrl: this.confCtrl, err: PasswordResetFormComponent.ERROR_REQUIRED, msg: ResetFormErrors.REQUIRED },
        { ctrl: this.confCtrl, err: PasswordResetFormComponent.ERROR_NOT_MATCHING, msg: ResetFormErrors.NOT_IDENTICAL },
      );
    }

    const errors = ReactiveFormsHelper.composeErrorMessages(fields);
    this.setErrorMessages(errors.errorMessages);
    this.#cdr.detectChanges();
  }

  /**
   * @private
   */
  private _buildForm(): void {
    this.passwordResetForm = this.#formBuilder.group(
      {
        apiResponse: null,
        password: [null, Validators.required],
        confirmPassword: [null, Validators.required],
      },
      { validators: confirmPasswordGroupValidator, updateOn: 'change' },
    );

    this.apiResponseCtrl = this.passwordResetForm.get('apiResponse') as UntypedFormControl;
    this.confCtrl = this.passwordResetForm.get('confirmPassword') as UntypedFormControl;
    this.passCtrl = this.passwordResetForm.get('password') as UntypedFormControl;
  }
}

enum ApiErrors {
  COMPROMISED_PASSWORD_ERROR = 'COMPROMISED_PASSWORD_ERROR',
  INVALID_LINK = 'INVALID_LINK',
  INVALID_TYPE_ERROR = 'INVALID_TYPE_ERROR',
  IS_BLANK_ERROR = 'IS_BLANK_ERROR',
  NOT_TRUE_ERROR = 'NOT_TRUE_ERROR',
  TOO_SHORT_ERROR = 'TOO_SHORT_ERROR',
  TOTP_CODE_INVALID = 'TOTP_CODE_INVALID',
}

enum ResetFormErrors {
  API_COMPROMISED = 'Passordet du valgte er allerede blitt kompromittert i datalekkasje hos andre nettsider, og er derfor ikke trygt å bruke. Velg et annet',
  API_INVALID_LINK = 'Denne lenken er ugyldig. Prøv igjen, eller bruk glemt passord-funksjonen for å få ny e-post tilsendt',
  API_INVALID_TYPE_ERROR = 'Noe gikk galt, prøv igjen',
  API_IS_BLANK_ERROR = 'Passord må angis',
  API_NOT_TRUE_ERROR = 'Passordene er ikke like - dobbeltsjekk at ditt passord er skrevet likt i begge feltene',
  API_TOO_SHORT_ERROR = 'Passordet du valgte er for kort',
  NOT_IDENTICAL = 'Passordene er ikke like - dobbeltsjekk at ditt passord er skrevet likt i begge feltene',
  REQUIRED = 'Du må oppgi passord i begge felt for å gå videre',
}
