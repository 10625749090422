<h1>
  Logg inn @if (loginSession.clientInfo.name) {
  <span>i {{ loginSession.clientInfo.name }}</span>
}
<span class="subtitle">Velg innloggingsmetode</span>
</h1>

<div #focusRef (keydown.enter)="$event.preventDefault()" class="login-options" tabindex="-1">
  @for (loginProvider of providersOrdered; track loginProvider; let idx = $index) {
    <button
      (click)="nextStep(loginProvider)"
      (keyup.enter)="nextStep(loginProvider)"
      [disabled]="processing"
      class="provider"
      title="Logg inn med {{ loginProvider.name }}"
      >
      <span class="logo" role="presentation">
        @if (loginProvider?.icon?.toString().length > 0) {
          <img [src]="loginProvider.icon" alt="" />
        }
      </span>
      {{ loginProvider.name }}
      @if (loginProvider.uuid === clickedProvider?.uuid) {
        <app-ff-loading-indicator
          class="spinner"
          size="s18"
          stroke="#222"
        ></app-ff-loading-indicator>
      }
    </button>
  }
</div>

<app-feedback-messages #feedbackMessagesRef [messages]="errorMessages"></app-feedback-messages>

<app-login-form-alternative-links
  (redirect)="redirectTo($event)"
  [credentials]="loginSession.credentials"
  [links]="linkAlternatives"
  [oauthAuthorizeRedirectQueryParams]="oauthAuthorizeRedirectQueryParams"
></app-login-form-alternative-links>
