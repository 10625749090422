import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginCredentials } from '@core/entities/login/login-credentials.entity';
import { LoginFormAlternativeLinkEntity } from '@core/entities/login/login-form-alternative-link.entity';
import { LoginFormRedirect } from '@core/entities/login/login-form-redirect.entity';
import { AlternativeLinksItemEntity } from '@core/entities/site/alternative-links-item.entity';
import { LoginFormEntries } from '@core/enums/login-form-entries.enum';
import { QueryParamInterface } from '@core/interfaces/query-param.interface';
import { AuthenticationService } from '@core/services/authentication/authentication.service';
import { finalize, tap } from 'rxjs/operators';
import { OauthAuthorizeRedirectQueryParamsEntity } from '@core/entities/query-params/oauth-authorize-redirect-query-params.entity';
import {
  LoginFormLinkItemComponent
} from '@shared/components/login-forms/login-form-alternative-links/login-form-link-item/login-form-link-item.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-login-form-alternative-links',
  templateUrl: './login-form-alternative-links.component.html',
  styleUrls: ['./login-form-alternative-links.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    LoginFormLinkItemComponent,
  ],
})
export class LoginFormAlternativeLinksComponent implements OnInit {
  @Output()
  clicked: EventEmitter<AlternativeLinksItemEntity> = new EventEmitter<AlternativeLinksItemEntity>();

  @Input()
  credentials?: LoginCredentials;

  @Input()
  queryParamsOverride?: QueryParamInterface;

  @Input()
  links: LoginFormAlternativeLinkEntity;

  @Input()
  oauthAuthorizeRedirectQueryParams?: OauthAuthorizeRedirectQueryParamsEntity;

  @Output()
  redirect: EventEmitter<LoginFormRedirect> = new EventEmitter<LoginFormRedirect>();

  #activatedRoute = inject(ActivatedRoute);
  #authenticationService = inject(AuthenticationService);
  #cdr = inject(ChangeDetectorRef);
  #destroyRef = inject(DestroyRef);
  #router = inject(Router);

  items: AlternativeLinksItemEntity[] = [];
  forms = LoginFormEntries;
  sendingSms: boolean = false;

  ngOnInit(): void {
    this._buildLinks();
  }

  linkClicked(item: AlternativeLinksItemEntity): void {
    this.clicked.emit(item);
    item.itemClicked();
  }

  sendSmsOtp(): void {
    if (this.sendingSms) {
      return;
    }

    this.sendingSms = true;
    this.#cdr.detectChanges();

    // In lack of other endpoints, call /create-session again rather than the deprecated 'v1/auth/sendSMSOTP'
    this.#authenticationService
      .startSsnMobileLoginSession(this.credentials, this.oauthAuthorizeRedirectQueryParams?.clientAuthAttemptUuid)
      .pipe(
        tap((entryToken: string) => {
          this.credentials.entryToken = entryToken;
        }),
        takeUntilDestroyed(this.#destroyRef),
        finalize(() => {
          this.sendingSms = false;
          this.#cdr.detectChanges();
        })
      )
      .subscribe();
  }

  showLoginForm(form: LoginFormEntries, forceResetQueryParams?: boolean): void {
    const redirect = new LoginFormRedirect();
    redirect.component = form;

    if (this.queryParamsOverride || forceResetQueryParams) {
      this._resetQueryParams(redirect);
      return;
    }

    this._emitRedirect(redirect);
  }

  private _buildLinks(): void {
    if (this.links.showEnterPassword) {
      this.items.push(
        new AlternativeLinksItemEntity({
          callback: {
            fn: this.showLoginForm,
            bind: this,
            args: [this.forms.PASSWORD],
          },
          id: LoginFormAlternativeLinkIdsEnum.ENTER_PASSWORD,
          iconPath: '/icons/key.svg',
          label: OptionStringsEnum.ENTER_PASSWORD,
        })
      );
    }

    if (this.links.showForgotPassword) {
      this.items.push(
        new AlternativeLinksItemEntity({
          callback: {
            fn: this.showLoginForm,
            bind: this,
            args: [this.forms.FORGOTTEN_PASSWORD],
          },
          id: LoginFormAlternativeLinkIdsEnum.FORGOT_PASSWORD,
          iconPath: '/icons/key.svg',
          label: OptionStringsEnum.FORGOT_PASSWORD,
        })
      );
    }

    if (this.links.showSmsResend) {
      this.items.push(
        new AlternativeLinksItemEntity({
          callback: {
            fn: this.sendSmsOtp,
            bind: this,
            args: [],
          },
          id: LoginFormAlternativeLinkIdsEnum.RESEND_SMS,
          iconPath: '/icons/sms_code.svg',
          label: OptionStringsEnum.SMS_RESEND,
        })
      );
    }

    if (this.links.showRestart) {
      this.items.push(
        new AlternativeLinksItemEntity({
          callback: {
            fn: this.showLoginForm,
            bind: this,
            args: [this.forms.LOGIN_OPTIONS, false],
          },
          id: LoginFormAlternativeLinkIdsEnum.RESTART_LOGIN,
          iconPath: '/icons/loop_forward.svg',
          label: OptionStringsEnum.RESTART,
        })
      );
    }

    this.#cdr.detectChanges();
  }

  private _resetQueryParams(redirect: LoginFormRedirect): void {
    let queryParams = { ...this.#activatedRoute.snapshot.queryParams };

    if (this.queryParamsOverride) {
      this.queryParamsOverride.strip.forEach((queryParam) => {
        delete queryParams[queryParam];
      });
    } else {
      queryParams = {};
    }

    this.#router
      .navigate([], {
        relativeTo: this.#activatedRoute,
        queryParams,
      })
      .then(() => {
        this._emitRedirect(redirect);
      })
      .catch(() => {});
  }

  private _emitRedirect(redirect: LoginFormRedirect): void {
    this.redirect.emit(redirect);
  }
}

export enum LoginFormAlternativeLinkIdsEnum {
  ENTER_PASSWORD = 'enter_password',
  FORGOT_PASSWORD = 'forgot_password',
  HELP = 'help',
  RESEND_SMS = 'resend_sms',
  RESTART_LOGIN = 'restart_login',
}

enum OptionStringsEnum {
  CANCEL = 'Avbryt innlogging',
  ENTER_PASSWORD = 'Oppgi passordet',
  FORGOT_PASSWORD = 'Jeg har glemt passordet',
  HELP = 'Jeg trenger hjelp',
  RESTART = 'Start innlogging på ny',
  SMS_RESEND = 'Send ny engangskode på SMS',
}
