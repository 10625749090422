import {
  ClientErrorResponse,
  ClientErrorResponseSchema,
} from '@core/entities/http-responses/client-error-response.entity';

export class UnauthorizedResponse extends ClientErrorResponse {}

export const UnauthorizedResponseSchema = {
  factory: () => new UnauthorizedResponse(),
  extends: ClientErrorResponseSchema,
  props: {},
};
