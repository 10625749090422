import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { PhoneCountryCodeEntity } from '@core/entities/country-codes/phone-country-code.entity';
import { LoginCredentials } from '@core/entities/login/login-credentials.entity';
import { LoginFormAlternativeLinkEntity } from '@core/entities/login/login-form-alternative-link.entity';
import { LoginFormRedirect } from '@core/entities/login/login-form-redirect.entity';
import { LoginFormEntries } from '@core/enums/login-form-entries.enum';
import { ErrorHandleHelper } from '@core/helpers/error-handle.helper';
import { ReactiveFormsHelper } from '@core/helpers/reactive-forms.helper';
import { FieldErrorMessageInterface } from '@core/interfaces/field-error-message.interface';
import { AuthenticationService } from '@core/services/authentication/authentication.service';
import { PhoneService } from '@core/services/user/phone.service';
import { BaseLoginFormComponent } from '@shared/components/login-forms/base-login-form.component';
import { PHONE_ERROR_MAPPING_API, PHONE_ERROR_MAPPING } from '@shared/validators/phone.validator';
import { NinValidator } from '@shared/validators/nin.validator';
import { Observable } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { deserialize } from 'serializr';
import { UserFormBuilder } from '@shared/components/user-form/user-form.factory';
import { PhoneErrorLabels } from '@shared/components/user-form/phones/phone-form/phone-form.component';
import { SharedModule } from '@shared/shared.module';
import {
  LoginFormAlternativeLinksComponent
} from '@shared/components/login-forms/login-form-alternative-links/login-form-alternative-links.component';

@Component({
  selector: 'app-credentials-form',
  templateUrl: './credentials-form.component.html',
  styleUrls: ['./credentials-form.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SharedModule,
    LoginFormAlternativeLinksComponent,
  ],
})
export class CredentialsFormComponent extends BaseLoginFormComponent implements OnInit {
  static readonly ERROR_GENERIC = 'error';
  readonly linkAlternatives = new LoginFormAlternativeLinkEntity({ restart: true });

  #authenticationService = inject(AuthenticationService);
  #cdr = inject(ChangeDetectorRef);
  #destroyRef = inject(DestroyRef);
  #phoneService = inject(PhoneService);
  #ufb = inject(UserFormBuilder);

  loginForm = this.#ufb.loginForm();
  countryCodes$: Observable<PhoneCountryCodeEntity[]>;

  ngOnInit(): void {
    this.countryCodes$ = this.#phoneService.getPhoneCountryCodes();
  }

  /**
   * Take user to next login step
   */
  nextStep(): void {
    this.submitted = true;

    if (this.loginForm.invalid) {
      this.showErrorsIfSubmitted();
      return;
    }

    this.processing = true;
    this.loginSession.credentials = deserialize(LoginCredentials, this.loginForm.value);
    this._figureOutWhatsNext();
  }

  /**
   * Use helper to generate strings explaining each field error constant
   */
  showErrorsIfSubmitted(): void {
    if (!this.submitted) {
      return;
    }

    this.setErrorMessages([]);
    const phoneGrp = this.loginForm.controls.phone;
    const ssnCtrl = this.loginForm.controls.ssn;

    const libPhoneErrors: FieldErrorMessageInterface[] = [];
    Object.keys(PhoneErrorLabels).forEach((errKey) => {
      libPhoneErrors.push({
        ctrl: phoneGrp,
        err: PHONE_ERROR_MAPPING_API[errKey],
        msg: PhoneErrorLabels[errKey],
      });
    });

    const errors = ReactiveFormsHelper.composeErrorMessages([
      {
        ctrl: phoneGrp.controls.number,
        err: PHONE_ERROR_MAPPING.INVALID,
        msg: CredentialsFormErrorsEnum.PHONE_INVALID,
      },
      {
        ctrl: phoneGrp.controls.number,
        err: PHONE_ERROR_MAPPING.REQUIRED,
        msg: CredentialsFormErrorsEnum.PHONE_REQUIRED,
      },
      { ctrl: ssnCtrl, err: NinValidator.ERROR_SYNTAX_INVALID, msg: CredentialsFormErrorsEnum.SSN_SYNTAX_INVALID },
      { ctrl: ssnCtrl, err: NinValidator.ERROR_REQUIRED, msg: CredentialsFormErrorsEnum.SSN_REQUIRED },
      { ctrl: ssnCtrl, err: CredentialsFormComponent.ERROR_GENERIC, msg: CredentialsFormErrorsEnum.ERROR },
      ...libPhoneErrors,
    ]);

    this.setErrorMessages(errors.errorMessages);
    this.#cdr.detectChanges();
  }

  private _figureOutWhatsNext(): void {
    const redirect = new LoginFormRedirect();
    redirect.loginSession = this.loginSession;

    this.#authenticationService
      .startSsnMobileLoginSession(
        this.loginSession.credentials,
        this.oauthAuthorizeRedirectQueryParams?.clientAuthAttemptUuid,
      )
      .pipe(
        tap((entryToken: string) => {
          redirect.loginSession.credentials.entryToken = entryToken;
          redirect.component = LoginFormEntries.OTP;
          this.redirectTo(redirect);
        }),
        catchError((error: HttpErrorResponse) => {
          this.processing = false;
          this._handleLoginSessionErrors(error);
          return ErrorHandleHelper.handleError(error);
        }),
        finalize(() => {
          this.#cdr.detectChanges();
        }),
      )
      .subscribe();
  }

  private _handleLoginSessionErrors(error: any): void {
    let handled = false;

    error.error.violations.forEach((violation) => {
      if ((violation.propertyPath as string).indexOf('phoneNumber') > -1) {
        handled = true;
        let errorsObj = { [PHONE_ERROR_MAPPING.INVALID]: true };
        if (violation?.errorName in PHONE_ERROR_MAPPING_API) {
          errorsObj[PHONE_ERROR_MAPPING_API[violation.errorName]] = true;
        }

        this.loginForm.controls.phone.setErrors(errorsObj);
      }
      if ((violation.propertyPath as string).indexOf('ssn') > -1) {
        handled = true;
        this.loginForm.get('ssn').setErrors({ [NinValidator.ERROR_INVALID]: true });
      }
    });

    // Generic error msg as catch-all, for 500 or other unhandled
    if (!handled) {
      this.loginForm.get('ssn').setErrors({ [CredentialsFormComponent.ERROR_GENERIC]: true });
    }

    this.showErrorsIfSubmitted();
  }
}

enum CredentialsFormErrorsEnum {
  ERROR = 'Noe gikk galt. Vennligst prøv på ny eller kontakt Fagforbundet',
  PHONE_INVALID = 'Feil i telefonnummer',
  PHONE_REQUIRED = 'Vennligst fyll ut ditt telefonnummer',
  SSN_SYNTAX_INVALID = 'Det angitte nummeret er ikke et gyldig norsk 11-sifret fødselsnummer',
  SSN_REQUIRED = 'Vennligst fyll ut ditt fødselsnummer',
}
