import { Component, OnInit } from '@angular/core';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { NavigationHelper } from '@core/helpers/navigation.helper';
import { AuthenticationService } from '@core/services/authentication/authentication.service';
import { SessionService } from '@core/services/user/session.service';
import { throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  readonly defaultLogoutUrl = EnvironmentHelper.getLogoutUrl() || 'https://fagforbundet.no';

  constructor(private _authenticationService: AuthenticationService, private _sessionService: SessionService) {}

  ngOnInit() {
    this._authenticationService
      .logout()
      .pipe(
        take(1),
        catchError((error: any) => {
          // Logout should not return 401, but need to handle for now
          if (error.status === 401) {
            return throwError(() => error);
          }

          this.redirect(this.defaultLogoutUrl);
          return throwError(() => error);
        }),
      )
      .subscribe();
  }

  redirect(redirectUrl: string): void {
    NavigationHelper.redirect(redirectUrl);
  }
}
