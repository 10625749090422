import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorPageErrorsEnum } from '@shared/components/ff-error-page/ff-error-page.component';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  errorType: ErrorPageErrorsEnum;

  constructor(private _activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this._resolveError();
  }

  private _resolveError(): void {
    this._activatedRoute.queryParams
      .pipe(
        tap((params) => {
          this.errorType = params['error_type'];
        }),
      )
      .subscribe();
  }
}
