import { ClientEntity } from '@core/entities/client/client.entity';
import { LoginCredentials } from '@core/entities/login/login-credentials.entity';
import { LoginProviderEntity } from '@core/entities/login/login-provider.entity';
import { ResetPasswordQueryParamsEntity } from '@core/entities/query-params/reset-password-query-params.entity';

export class LoginSession {
  private _availableLoginProviders: LoginProviderEntity[] = []; // TODO: Refactor to ClientEntity when API supports it
  private _clientInfo: ClientEntity;
  private _credentials: LoginCredentials;
  private _isResetPasswordCallback: boolean;
  private _resetPasswordQueryParamsEntity: ResetPasswordQueryParamsEntity;
  private _selectedLoginProvider: LoginProviderEntity;

  constructor(clientInfo: ClientEntity, credentials: LoginCredentials, loginProviders: LoginProviderEntity[]) {
    this._clientInfo = clientInfo;
    this._credentials = credentials;
    this._availableLoginProviders = loginProviders;
  }

  get availableLoginProviders(): LoginProviderEntity[] {
    return this._availableLoginProviders;
  }

  set availableLoginProviders(loginProviders: LoginProviderEntity[]) {
    this._availableLoginProviders = loginProviders;
  }

  get clientInfo(): ClientEntity {
    return this._clientInfo;
  }

  set clientInfo(clientInfo: ClientEntity) {
    this._clientInfo = clientInfo;
  }

  get credentials(): LoginCredentials {
    return this._credentials;
  }

  set credentials(loginCredentials: LoginCredentials) {
    this._credentials = loginCredentials;
  }

  get resetPasswordQueryParamsEntity(): ResetPasswordQueryParamsEntity {
    return this._resetPasswordQueryParamsEntity;
  }

  set resetPasswordQueryParamsEntity(resetPasswordQueryParamsEntity: ResetPasswordQueryParamsEntity) {
    this._resetPasswordQueryParamsEntity = resetPasswordQueryParamsEntity;
  }

  get isResetPasswordCallback(): boolean {
    return this._isResetPasswordCallback;
  }

  set isResetPasswordCallback(isResetPasswordCallback: boolean) {
    this._isResetPasswordCallback = isResetPasswordCallback;
  }

  get selectedLoginProvider(): LoginProviderEntity {
    return this._selectedLoginProvider;
  }

  set selectedLoginProvider(value: LoginProviderEntity) {
    this._selectedLoginProvider = value;
  }
}
