import { BadRequestResponseSchema } from '@core/entities/http-responses/bad-request-response.entity';
import { BaseResponseSchema } from '@core/entities/http-responses/base-response.entity';
import { ForbiddenResponseSchema } from '@core/entities/http-responses/forbidden-response.entity';
import { NotFoundResponseSchema } from '@core/entities/http-responses/not-found-response.entity';
import { PreconditionRequiredResponseSchema } from '@core/entities/http-responses/precondition-required-response.entity';
import { UnauthorizedResponseSchema } from '@core/entities/http-responses/unauthorized-response.entity';
import { throwError } from 'rxjs';
import { deserialize } from 'serializr';

/**
 *
 */
export class ErrorHandleHelper {
  /**
   *
   * @param error
   */
  static handleError(error: any) {
    if (!error.status || error.status < 400) {
      return throwError(() => error);
    }

    let newAndBetterError;

    switch (error.status) {
      case 400:
        newAndBetterError = deserialize(BadRequestResponseSchema, error);
        break;
      case 401:
        newAndBetterError = deserialize(UnauthorizedResponseSchema, error);
        break;
      case 403:
        newAndBetterError = deserialize(ForbiddenResponseSchema, error);
        break;
      case 404:
        newAndBetterError = deserialize(NotFoundResponseSchema, error);
        break;
      case 428:
        newAndBetterError = deserialize(PreconditionRequiredResponseSchema, error);
        break;
      default:
        newAndBetterError = deserialize(BaseResponseSchema, error);
    }

    return throwError(() => newAndBetterError);
  }
}
