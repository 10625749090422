import { OauthAuthorizeRedirectErrorsEnum } from '@core/enums/oauth-authorize-redirect-errors.enum';
import { alias, custom, serializable } from 'serializr';
import { Params } from '@angular/router';

export class OauthAuthorizeRedirectQueryParamsEntity {
  @serializable(alias('access_token'))
  private _accessToken: string;

  @serializable(alias('client_id'))
  private _clientId: string;

  @serializable(alias('client_auth_attempt_uuid'))
  private _clientAuthAttemptUuid: string;

  @serializable(alias('code'))
  private _code: string;

  @serializable(alias('error'))
  private _error: string;

  @serializable(alias('error_description'))
  private _errorDescription: string;

  @serializable(alias('expires_in'))
  private _expiresIn: string;

  @serializable(alias('id_token'))
  private _idToken: string;

  @serializable(alias('internal_error_code'))
  private _internalErrorCode: OauthAuthorizeRedirectErrorsEnum;

  @serializable(alias('redir'))
  private _redir: string;

  @serializable(alias('redirect_uri'))
  private _redirectUri: string;

  @serializable(alias('refresh_token'))
  private _refreshToken: string;

  @serializable(alias('response_mode'))
  private _responseMode: string;

  @serializable(alias('state'))
  private _state: string;

  @serializable(alias('token_type'))
  private _tokenType: string;

  @serializable(alias('isOidcCallback', custom(
    (v) => { return v; },
    (v) => { return v === 'true'; })
  ))
  private _isOidcCallback: boolean;

  private _rawParams: Params;

  get isOidcCallback(): boolean {
    return this._isOidcCallback;
  }

  set isOidcCallback(value: boolean) {
    this._isOidcCallback = value;
  }

  get refreshToken(): string {
    return this._refreshToken;
  }

  get expiresIn(): string {
    return this._expiresIn;
  }

  get state(): string {
    return this._state;
  }

  get idToken(): string {
    return this._idToken;
  }

  get code(): string {
    return this._code;
  }

  get tokenType(): string {
    return this._tokenType;
  }

  get accessToken(): string {
    return this._accessToken;
  }

  get internalErrorCode(): OauthAuthorizeRedirectErrorsEnum {
    return this._internalErrorCode;
  }

  get redirectUri(): string {
    return this._redirectUri;
  }

  get responseMode(): string {
    return this._responseMode;
  }

  get redir(): string {
    return this._redir;
  }

  /**
   * @param {string} value
   */
  set redir(value: string) {
    this._redir = value;
  }

  get error(): string {
    return this._error;
  }

  get errorDescription(): string {
    return this._errorDescription;
  }

  get clientId(): string {
    return this._clientId;
  }

  get clientAuthAttemptUuid(): string {
    return this._clientAuthAttemptUuid;
  }

  get rawParams(): Params {
    return this._rawParams;
  }

  /**
   * @param value
   */
  set rawParams(value: Params) {
    this._rawParams = value;
  }
}
