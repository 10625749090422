<ul class="alternative-links alt" data-cy="alternative-links">
  @for (item of items; track item) {
    <li
      (click)="linkClicked(item)"
      (focusin)="item.active = true"
      (focusout)="item.active = false"
      (keyup.enter)="linkClicked(item)"
      (mouseenter)="item.active = true"
      (mouseleave)="item.active = false"
      [attr.data-cy]="item.id"
      [class.in-progress]="sendingSms"
      tabindex="0"
      >
      <app-login-form-link-item [class.active]="item.active" [item]="item"></app-login-form-link-item>
    </li>
  }
</ul>
