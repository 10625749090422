import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { PendingInformationUpdates } from '@core/entities/login/pending-information-updates.entity';
import {
  OauthAuthorizeRedirectQueryParamsEntity,
} from '@core/entities/query-params/oauth-authorize-redirect-query-params.entity';
import { OauthAuthorizeRedirectErrorsEnum } from '@core/enums/oauth-authorize-redirect-errors.enum';
import { UserService } from '@core/services/user/user.service';
import { of } from 'rxjs';
import { deserialize } from 'serializr';

export const oauthPendingInformationUpdateResolver = (): ResolveFn<PendingInformationUpdates | null> => {
  return (activatedRouteSnapshot: ActivatedRouteSnapshot) => {
    const userService = inject(UserService);

    const oauthAuthorizeRedirectRequest = deserialize(
      OauthAuthorizeRedirectQueryParamsEntity,
      activatedRouteSnapshot.queryParams
    );

    if (
      oauthAuthorizeRedirectRequest.internalErrorCode === OauthAuthorizeRedirectErrorsEnum.USER_INFORMATION_REQUIRED
    ) {
      return userService.getPendingInformationUpdates(oauthAuthorizeRedirectRequest.clientId);
    } else {
      return of(null);
    }
  };
};
