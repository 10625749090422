import { alias, object, serializable } from 'serializr';
import { LoginPhone } from './login-phone.entity';

export class LoginCredentials {
  @serializable(alias('ssn'))
  private _ssn: string;

  @serializable(alias('phone', object(LoginPhone)))
  private _phone: LoginPhone;

  @serializable(alias('birthDate'))
  private _birthDate: string;

  @serializable(alias('password'))
  private _password: string;

  @serializable(alias('otp'))
  private _otp: string;

  @serializable(alias('entryToken'))
  private _entryToken: string;

  /**
   * @param ssn
   */
  set ssn(ssn: string) {
    this._ssn = ssn;
  }

  get ssn(): string {
    return this._ssn;
  }

  /**
   * @param phone
   */
  set phone(phone: LoginPhone) {
    this._phone = phone;
  }

  get phone(): LoginPhone {
    return this._phone;
  }

  get birthDate(): string {
    return this._birthDate;
  }

  set birthDate(value: string) {
    this._birthDate = value;
  }

  set password(password: string) {
    this._password = password;
  }

  get password(): string {
    return this._password;
  }

  /**
   * @param otp
   */
  set otp(otp: string) {
    this._otp = otp;
  }

  get otp(): string {
    return this._otp;
  }

  get entryToken(): string {
    return this._entryToken;
  }

  /**
   * @param entryToken
   */
  set entryToken(entryToken: string) {
    this._entryToken = entryToken;
  }
}
