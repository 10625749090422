import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const confirmPasswordGroupValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const pass = control.get('password');
  const confirm =
    control.get('confirmPassword') !== null ? control.get('confirmPassword') : control.get('repeatPassword');
  const result = pass && confirm && pass.value !== confirm.value ? { not_matching: true } : null;
  confirm.setErrors(result);
  return result;
};
