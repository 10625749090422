/**
 *
 */
export enum OauthAuthorizeRedirectErrorsEnum {
  CLIENT_NOT_FOUND = 'CLIENT_NOT_FOUND',
  REDIRECT_URI_DID_NOT_MATCH_CLIENT = 'REDIRECT_URI_DID_NOT_MATCH_CLIENT',
  INVALID_REDIRECT_URI = 'INVALID_REDIRECT_URI',
  MISSING_CLIENT_ID = 'MISSING_CLIENT_ID',
  MISSING_REDIRECT_URI = 'MISSING_REDIRECT_URI',
  DEV_CLIENT_NO_ACCESS = 'DEV_CLIENT_NO_ACCESS',
  LOGIN_REQUIRED = 'LOGIN_REQUIRED',
  LOGIN_REQUIRED_MAX_AUTH_TIME_EXCEEDED = 'LOGIN_REQUIRED_MAX_AUTH_TIME_EXCEEDED',
  SCOPE_INVALID = 'SCOPE_INVALID',
  USER_INFORMATION_REQUIRED = 'USER_INFORMATION_REQUIRED',
  CONSENT_REQUIRED = 'CONSENT_REQUIRED',
}
