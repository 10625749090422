import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginFormAlternativeLinkEntity } from '@core/entities/login/login-form-alternative-link.entity';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { NavigationHelper } from '@core/helpers/navigation.helper';
import { ReactiveFormsHelper } from '@core/helpers/reactive-forms.helper';
import { AuthenticationService } from '@core/services/authentication/authentication.service';
import { BaseLoginFormComponent } from '@shared/components/login-forms/base-login-form.component';
import { EmailValidator } from '@shared/validators/email.validator';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SharedModule } from '@shared/shared.module';
import {
  LoginFormAlternativeLinksComponent
} from '@shared/components/login-forms/login-form-alternative-links/login-form-alternative-links.component';

@Component({
  selector: 'app-forgotten-password-form',
  templateUrl: './forgotten-password-form.component.html',
  styleUrls: ['./forgotten-password-form.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SharedModule,
    LoginFormAlternativeLinksComponent,
  ],
})
export class ForgottenPasswordFormComponent extends BaseLoginFormComponent implements OnInit {
  readonly linkAlternatives = new LoginFormAlternativeLinkEntity({ enterPassword: true, restart: true });
  forgottenPasswordForm: UntypedFormGroup;
  formHelper = ReactiveFormsHelper;
  showForgotPasswordInstruction: boolean = false;
  #authenticationService = inject(AuthenticationService);
  #cdr = inject(ChangeDetectorRef);
  #destroyRef = inject(DestroyRef);
  #formBuilder = inject(UntypedFormBuilder);
  #router = inject(Router);

  ngOnInit(): void {
    this.forgottenPasswordForm = this.#formBuilder.group({
      email: [null, [Validators.required, EmailValidator.validEmailSyntax]],
    });
  }

  nextStep(): void {
    this.submitted = true;

    if (this.forgottenPasswordForm.invalid) {
      this.showErrorsIfSubmitted();
      this.#cdr.detectChanges();
      return;
    }

    this.processing = true;

    this.#cdr.detectChanges();

    // TODO: See if it's possible to "clean" URL before adding param
    // TODO: to avoid user interference (user adding params to url manually).
    // TODO: Needs to handle dynamic url (reset password url depends on route (oauth, sso, direct login))
    this.#authenticationService
      .forgotPassword(
        this.loginSession.credentials.ssn,
        this.forgottenPasswordForm.get('email').value,
        NavigationHelper.addParamToUrl(
          EnvironmentHelper.fetchFrontEndURL(this.#router.url),
          'action',
          'reset-password',
        ),
      ).pipe(
      takeUntilDestroyed(this.#destroyRef),
    ).subscribe(() => {
      this.processing = false;
      this.showForgotPasswordInstruction = true;
      this.#cdr.detectChanges();
    });
  }

  /**
   * Use helper to generate strings explaining each field error constant
   */
  showErrorsIfSubmitted(): void {
    if (!this.submitted) {
      return;
    }

    this.setErrorMessages([]);

    const emailCtrl = this.forgottenPasswordForm.get('email') as UntypedFormControl;

    const errors = ReactiveFormsHelper.composeErrorMessages([
      { ctrl: emailCtrl, err: 'required', msg: ForgottenPasswordFormErrorsEnum.EMAIL_REQUIRED },
      { ctrl: emailCtrl, err: EmailValidator.ERROR_INVALID_EMAIL, msg: ForgottenPasswordFormErrorsEnum.EMAIL_INVALID },
    ]);

    this.setErrorMessages(errors.errorMessages);
    this.#cdr.detectChanges();
  }
}

enum ForgottenPasswordFormErrorsEnum {
  EMAIL_REQUIRED = 'Vennligst fyll ut din e-postadresse',
  EMAIL_INVALID = 'Du må oppgi en gyldig e-postadresse',
}
