import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@core/entities/user/user.entity';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
})
export class SetPasswordComponent implements OnDestroy, OnInit {
  user: User;

  private readonly _onDestroy$ = new Subject<void>();

  private _proceedTo: string;

  constructor(private _activatedRoute: ActivatedRoute, private _location: Location, private _router: Router) {}

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngOnInit() {
    this._activatedRoute.queryParams
      .pipe(
        tap((params) => {
          this._proceedTo = params['proceedTo'];

          if (params['mask']) {
            this._location.replaceState(this._proceedTo);
          }
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();

    this._activatedRoute.data
      .pipe(
        tap((data: { selfUser: User }) => {
          this.user = data.selfUser;
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();
  }

  proceed() {
    void this._router.navigateByUrl(this._proceedTo);
  }
}
