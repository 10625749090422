/**
 *
 */
export interface QueryParamInterface {
  strip: Array<QueryParamEnum>;
}

export enum QueryParamEnum {
  ACTION = 'action',
  CODE = 'code',
  EXTERNAL_ID = 'externalid',
  REQUEST_ID = 'requestid',
  STATUS = 'status',
  UUID = 'uuid',
}
