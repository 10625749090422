import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginFormAlternativeLinkEntity } from '@core/entities/login/login-form-alternative-link.entity';
import { LoginFormRedirect } from '@core/entities/login/login-form-redirect.entity';
import { LoginResponse } from '@core/entities/response/login-response.entity';
import { AlternativeLinksItemEntity } from '@core/entities/site/alternative-links-item.entity';
import { LoginFormEntries } from '@core/enums/login-form-entries.enum';
import { ReactiveFormsHelper } from '@core/helpers/reactive-forms.helper';
import { AuthenticationService } from '@core/services/authentication/authentication.service';
import { ErrorPageErrorsEnum } from '@shared/components/ff-error-page/ff-error-page.component';
import { BaseLoginFormComponent } from '@shared/components/login-forms/base-login-form.component';
import {
  LoginFormAlternativeLinkIdsEnum,
  LoginFormAlternativeLinksComponent,
} from '@shared/components/login-forms/login-form-alternative-links/login-form-alternative-links.component';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SharedModule } from '@shared/shared.module';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-otp-form',
  templateUrl: './otp-form.component.html',
  styleUrls: ['./otp-form.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SharedModule,
    LoginFormAlternativeLinksComponent,
  ],
})
export class OtpFormComponent extends BaseLoginFormComponent implements OnInit {
  static readonly ERROR_GENERIC = 'error';
  static readonly ERROR_INCORRECT_OTP = 'incorrectOTP';
  static readonly ERROR_OTP_ERROR = 'OTPError';

  #authenticationService = inject(AuthenticationService);
  #cdr = inject(ChangeDetectorRef);
  #destroyRef = inject(DestroyRef);
  #formBuilder = inject(UntypedFormBuilder);
  #router = inject(Router);

  readonly linkAlternatives = new LoginFormAlternativeLinkEntity({ restart: true, smsResend: true });
  formHelper = ReactiveFormsHelper;
  otpForm: UntypedFormGroup;

  ngOnInit(): void {
    this.otpForm = this.#formBuilder.group({
      otp: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
    });
  }

  /**
   * Callback to run when an alternative link was clicked
   */
  clickedAlternative(item: AlternativeLinksItemEntity): void {
    if (item.id === LoginFormAlternativeLinkIdsEnum.RESEND_SMS) {
      this.errorMessages = [];
      this.otpForm.reset();

      if (this.focusRef) {
        this.focusRef.nativeElement.focus();
      }
    }
  }

  nextStep(): void {
    this.submitted = true;

    if (this.otpForm.invalid) {
      this.showErrorsIfSubmitted();
      this.#cdr.detectChanges();
      return;
    }

    this.processing = true;
    this.#cdr.detectChanges();

    this.#authenticationService
      .login(this.otpForm.get('otp').value, this.loginSession.credentials.entryToken)
      .pipe(
        tap((loginResponse: LoginResponse) => {
          this.login(loginResponse);
          this.processing = false;
          this.#cdr.detectChanges();
        }),
        takeUntilDestroyed(this.#destroyRef),
        catchError((response: HttpErrorResponse) => {
          if (response.status === 400 && response.error.error === 'PASSWORD_REQUIRED') {
            const redirect = new LoginFormRedirect();
            this.loginSession.credentials.otp = this.otpForm.get('otp').value;
            redirect.loginSession = this.loginSession;
            redirect.component = LoginFormEntries.PASSWORD;
            this.redirectTo(redirect);
          }

          this.processing = false;
          this.otpForm.markAsDirty();
          const otpCtrl = this.otpForm.get('otp');
          otpCtrl.markAsDirty();
          otpCtrl.markAsTouched();

          if (response.error.error === 'USER_DEACTIVATED') {
            void this.#router.navigate(['error'], {
              queryParams: { error_type: ErrorPageErrorsEnum.USER_DEACTIVATED },
            });

            return throwError(() => response);
          }

          if (response.error.error === 'INVALID_OTP') {
            otpCtrl.setErrors({ [OtpFormComponent.ERROR_INCORRECT_OTP]: true });
          }

          // Generic error msg as catch-all, for 500 or other unhandled
          if (!otpCtrl.errors || Object.values(otpCtrl.errors).length < 1) {
            otpCtrl.setErrors({ [OtpFormComponent.ERROR_GENERIC]: true });
          }

          this.showErrorsIfSubmitted();
          this.#cdr.detectChanges();
          return throwError(() => response);
        })
      )
      .subscribe();
  }

  /**
   * Use helper to generate strings explaining each field error constant
   */
  showErrorsIfSubmitted(): void {
    if (!this.submitted) {
      return;
    }

    this.setErrorMessages([]);
    this.#cdr.detectChanges();

    const otpCtrl = this.otpForm.get('otp') as UntypedFormControl;

    const errors = ReactiveFormsHelper.composeErrorMessages([
      { ctrl: otpCtrl, err: OtpFormComponent.ERROR_GENERIC, msg: OtpFormErrorsEnum.ERROR },
      { ctrl: otpCtrl, err: OtpFormComponent.ERROR_INCORRECT_OTP, msg: OtpFormErrorsEnum.INCORRECT },
      { ctrl: otpCtrl, err: OtpFormComponent.ERROR_OTP_ERROR, msg: OtpFormErrorsEnum.OTP_ERROR },
      { ctrl: otpCtrl, err: 'required', msg: OtpFormErrorsEnum.REQUIRED },
      { ctrl: otpCtrl, err: 'maxlength', msg: OtpFormErrorsEnum.SYNTAX },
      { ctrl: otpCtrl, err: 'minlength', msg: OtpFormErrorsEnum.SYNTAX },
    ]);

    this.setErrorMessages(errors.errorMessages);
    this.#cdr.detectChanges();
  }
}

enum OtpFormErrorsEnum {
  ERROR = 'Noe gikk galt. Vennligst prøv på ny eller kontakt Fagforbundet',
  INCORRECT = 'Uffda, koden du tastet ser ut til å være ugyldig. Prøv igjen, eller be om ny kode nedenfor',
  OTP_ERROR = 'Noe gikk galt. Prøv igjen, eller be om ny kode nedenfor',
  REQUIRED = 'Engangskode er påkrevd. Ikke mottatt engangskode? Bruk linkene nedenfor til å be om ny kode eller starte innlogging på ny',
  SYNTAX = 'Engangskode skal være 6 siffer',
}
