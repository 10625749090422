import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedInGuard } from '@core/guards/logged-in.guard';
import { oauthClientInfoResolver } from '@core/resolvers/oauth-client-info.resolver';
import { oauthPendingInformationUpdateResolver } from '@core/resolvers/oauth-pending-information-update.resolver';
import { selfResolver } from '@core/resolvers/self.resolver';
import { ssoClientInfoResolver } from '@core/resolvers/sso-client-info.resolver';
import { ssoDataResolver } from '@core/resolvers/sso-data.resolver';
import { AboutComponent } from '@modules/public/pages/about/about.component';
import { AuthComponent } from '@modules/public/pages/auth/auth.component';
import { EmailVerificationComponent } from '@modules/public/pages/email-verification/email-verification.component';
import { ErrorComponent } from '@modules/public/pages/error/error.component';
import { LoginComponent } from '@modules/public/pages/login/login.component';
import { LogoutComponent } from '@modules/public/pages/logout/logout.component';
import { OpenidErrorHandlerComponent } from '@modules/public/pages/openid-error-handler/openid-error-handler.component';
import {
  OpenidResponseHandlerComponent,
} from '@modules/public/pages/openid-response-handler/openid-response-handler.component';
import { PageNotFoundComponent } from '@modules/public/pages/page-not-found/page-not-found.component';
import { PublicComponent } from '@modules/public/pages/public/public.component';
import { SetPasswordComponent } from '@modules/public/pages/set-password/set-password.component';
import { SsoComponent } from '@modules/public/pages/sso/sso.component';

const routes: Routes = [
  {
    path: '404', // TODO: Move to PublicComponent.children?
    component: PageNotFoundComponent,
    title: 'Feil 404 Ikke funnet',
  },
  {
    path: '',
    component: PublicComponent,
    title: 'Innlogging',
    children: [
      {
        path: '',
        component: AuthComponent,
        children: [
          {
            path: '',
            component: LoginComponent,
            canActivate: [LoggedInGuard],
          },
          {
            path: 'sso',
            component: SsoComponent,
            resolve: {
              clientInfo: ssoClientInfoResolver(),
              ssoData: ssoDataResolver(),
            },
          },
          {
            path: 'openid/error-handler',
            component: OpenidErrorHandlerComponent,
            resolve: {
              clientInfo: oauthClientInfoResolver(),
              pendingInformationUpdates: oauthPendingInformationUpdateResolver(),
            },
          },
          {
            path: 'openid/response-handler',
            component: OpenidResponseHandlerComponent,
          },
        ],
      },
      {
        path: 'about',
        component: AboutComponent,
        title: 'Om nettstedet',
      },
      {
        path: 'error',
        component: ErrorComponent,
        title: 'Feil',
      },
      {
        path: 'documentation',
        loadChildren: () => import('../documentation/documentation.module').then((m) => m.DocumentationModule),
      },
      {
        path: 'set-password',
        component: SetPasswordComponent,
        resolve: {
          selfUser: selfResolver(),
        },
        title: 'Sett passord',
      },
      {
        path: 'verification/email',
        component: EmailVerificationComponent,
        resolve: {
          selfUser: selfResolver(),
        },
        title: 'Bekreft e-postadresse',
      },
      {
        path: 'logout',
        component: LogoutComponent,
        title: 'Utlogging',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class PublicRoutingModule {}
