import { NgModule } from '@angular/core';
import { DocumentationModule } from '@modules/documentation/documentation.module';
import { EmailVerificationComponent } from '@modules/public/pages/email-verification/email-verification.component';
import {
  OpenidResponseHandlerComponent,
} from '@modules/public/pages/openid-response-handler/openid-response-handler.component';
import { PageNotFoundComponent } from '@modules/public/pages/page-not-found/page-not-found.component';
import { PublicRoutingModule } from '@modules/public/public-routing.module';
import { SharedModule } from '@shared/shared.module';
import { AboutComponent } from './pages/about/about.component';
import { AuthComponent } from './pages/auth/auth.component';
import { ErrorComponent } from './pages/error/error.component';
import { LoginComponent } from './pages/login/login.component';
import { LogoutComponent } from './pages/logout/logout.component';
import { PublicComponent } from './pages/public/public.component';
import { SetPasswordComponent } from './pages/set-password/set-password.component';
import { SsoComponent } from './pages/sso/sso.component';
import { NgxCookiebotComponent } from '@halloverden/ngx-cookiebot';

@NgModule({
  declarations: [
    AboutComponent,
    EmailVerificationComponent,
    ErrorComponent,
    LoginComponent,
    SsoComponent,
    OpenidResponseHandlerComponent,
    AuthComponent,
    LogoutComponent,
    PublicComponent,
    PageNotFoundComponent,
    SetPasswordComponent,
  ],
  imports: [PublicRoutingModule, DocumentationModule, SharedModule, NgxCookiebotComponent],
  exports: [PageNotFoundComponent],
})

export class PublicModule {
}
