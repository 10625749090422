@if (!success) {
  <div class="email-verification-page" data-cy="email-unverified">
    <div class="email-verification-container">
      <h1>
        Bekreftelse av e-post
        <span class="subtitle">Bekreft adressen din for å sikre at viktig informasjon når frem</span>
      </h1>
      <div class="result">
        <app-svg
          [heightPx]="48"
          [widthPx]="48"
          class="icon"
          role="presentation"
          svgPath="icons/envelope_open_check.svg"
        ></app-svg>
        @if (processing) {
          <div>
            <h2>Sjekker</h2>
            <app-ff-loading-indicator class="stroke--mine-shaft" size="medium"></app-ff-loading-indicator>
          </div>
        }
        @if (error) {
          <div>
            <h2>Feil</h2>
            <div>Noe gikk galt.</div>
            @if (!errorDetail) {
              <div>
                Klikk på lenken i e-posten igjen, eller be om ny bekreftelsesmelding fra
                <a routerLink="/">din profil</a>.
              </div>
            }
            @if (errorDetail) {
              <div class="result__details">
                <h3>Detaljer</h3>
                <div>{{ errorDetail }}.</div>
              </div>
            }
          </div>
        }
      </div>
    </div>
  </div>
}

@if (success) {
  <div class="email-verification-page verified" data-cy="email-verified">
    <div class="email-verification-container">
      <h1>
        E-posten er bekreftet!
        <span class="subtitle">
          Du kan nå bruke adressen med din konto hos Fagforbundet.
          <span class="link" routerLink="/">Din profil</span>
        </span>
      </h1>
      <a href="https://www.fagforbundet.no">Til fagforbundet.no</a>
    </div>
  </div>
}
