<h1>
  Personlig passord
  <span class="subtitle">Oppgi det personlige passordet du tidligere har satt for din konto</span>
</h1>

<form
  #formRef
  (keyup.enter)="nextStep()"
  [formGroup]="passwordForm"
  [class.submitted]="submitted"
  class="form"
  data-cy="form-password"
>
  <div class="form-group">
    <div class="form-control">
      <label for="password">Passord</label>
      <input
        #focusRef
        (input)="showErrorsIfSubmitted()"
        [formControl]="formHelper.getCtrl(passwordForm, 'password')"
        [readOnly]="processing ? true : null"
        autocomplete="current-password"
        data-cy="input-password"
        id="password"
        type="password"
      />
    </div>
  </div>

  <app-ff-button
    (clicked)="nextStep()"
    [processing]="processing"
    class="standalone"
    data-cy="button-next"
    iconPath="icons/arrow_right.svg"
  ></app-ff-button>
</form>

<app-feedback-messages #feedbackMessagesRef [messages]="errorMessages"></app-feedback-messages>

<app-login-form-alternative-links
  (redirect)="redirectTo($event)"
  [credentials]="loginSession.credentials"
  [links]="linkAlternatives"
  [oauthAuthorizeRedirectQueryParams]="oauthAuthorizeRedirectQueryParams"
></app-login-form-alternative-links>
