import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConsentStatusEntity } from '@core/entities/oauth/consent-status.entity';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { SessionService } from '@core/services/user/session.service';
import { OauthConsentResponseEntity } from '@shared/components/oauth-consent/oauth-consent-response.entity';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { deserialize } from 'serializr';

@Injectable({
  providedIn: 'root',
})
export class OauthAuthorizationService {
  constructor(private _httpClient: HttpClient, private _sessionService: SessionService) {}

  getConsentStatus(clientUuid: string, scopes: string[]): Observable<ConsentStatusEntity> {
    return this._httpClient
      .get(EnvironmentHelper.fetchAPIBase('v2/oauth/consent/' + clientUuid + '/status?scope=' + scopes.join('+')), {
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<{ consentStatus: {} }>) => {
          return deserialize(ConsentStatusEntity, response.body.consentStatus);
        }),
      );
  }

  setConsent(clientUuid: string, consent: OauthConsentResponseEntity): Observable<boolean> {
    return this._httpClient
      .post(EnvironmentHelper.fetchAPIBase('v2/oauth/consent/' + clientUuid + '/update'), {
        approvedScopes: consent.consentedScopes,
        rejectedScopes: consent.rejectedScopes,
      })
      .pipe(
        map(() => {
          return true;
        }),
      );
  }
}
