import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { AlternativeLinksItemEntity } from '@core/entities/site/alternative-links-item.entity';
import { SharedModule } from '@shared/shared.module';

@Component({
  selector: 'app-login-form-link-item',
  templateUrl: './login-form-link-item.component.html',
  styleUrls: ['./login-form-link-item.component.scss'],
  standalone: true,
  imports: [
    SharedModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormLinkItemComponent {
  @Input()
  item: AlternativeLinksItemEntity;

  @HostBinding('attr.role')
  readonly role: string = 'listitem';

  iconSize: number = 24;
}
