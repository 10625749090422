import { ILoginFormAlternativeLink } from '@core/interfaces/login-form-alternative-link.interface';

export class LoginFormAlternativeLinkEntity {
  private _enterPassword: boolean;
  private _forgotPassword: boolean;
  private _help: boolean;
  private _restart: boolean;
  private _smsResend: boolean;

  constructor(options: ILoginFormAlternativeLink) {
    this._enterPassword = options.enterPassword;
    this._forgotPassword = options.forgotPassword;
    this._help = options.help !== undefined ? options.help : true; // Defaults to true
    this._restart = options.restart;
    this._smsResend = options.smsResend;
  }

  get showEnterPassword(): boolean {
    return this._enterPassword;
  }

  get showForgotPassword(): boolean {
    return this._forgotPassword;
  }

  get showHelp(): boolean {
    return this._help;
  }

  get showRestart(): boolean {
    return this._restart;
  }

  get showSmsResend(): boolean {
    return this._smsResend;
  }

  set enterPassword(enterPassword: boolean) {
    this._enterPassword = enterPassword;
  }

  set forgotPassword(forgotPassword: boolean) {
    this._forgotPassword = forgotPassword;
  }

  set help(help: boolean) {
    this._help = help;
  }

  set restart(restart: boolean) {
    this._restart = restart;
  }

  set smsResend(smsResend: boolean) {
    this._smsResend = smsResend;
  }
}
