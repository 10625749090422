import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { OidcLoginEntity } from '@core/entities/oauth/oidc-login.entity';
import {
  OauthAuthorizeRedirectQueryParamsEntity,
} from '@core/entities/query-params/oauth-authorize-redirect-query-params.entity';
import { AuthenticationService } from '@core/services/authentication/authentication.service';
import { ErrorPageErrorsEnum } from '@shared/components/ff-error-page/ff-error-page.component';
import { throwError } from 'rxjs';
import { catchError, take, tap } from 'rxjs/operators';
import { deserialize } from 'serializr';

@Component({
  selector: 'app-openid-response-handler',
  templateUrl: './openid-response-handler.component.html',
  styleUrls: ['./openid-response-handler.component.scss'],
})
export class OpenidResponseHandlerComponent implements OnInit {
  @ViewChild('viewContainerRef', { read: ViewContainerRef })
  viewContainerRef: ViewContainerRef;

  processing = true;

  private _oauthAuthorizeResponse: OauthAuthorizeRedirectQueryParamsEntity;

  constructor(
    private _authenticationService: AuthenticationService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) {}

  ngOnInit() {
    this._activatedRoute.queryParams
      .pipe(
        tap((params: Params) => {
          this._oauthAuthorizeResponse = deserialize(OauthAuthorizeRedirectQueryParamsEntity, params);
          this._oauthAuthorizeResponse.rawParams = params;
          this._handleAuthorizationResponse();
        }),
        catchError((e) => {
          this._redirectToErrorPage();
          return throwError(() => e);
        })
      )
      .subscribe();
  }

  private _handleAuthorizationResponse(): void {
    // TODO: Handle 404 etc. (when refreshing page, code gets 'used' up)
    if (this._oauthAuthorizeResponse.code && this._oauthAuthorizeResponse.state) {
      this._authenticationService
        .oidcPostLogin(this._oauthAuthorizeResponse.state, this._oauthAuthorizeResponse.code)
        .pipe(
          take(1),
          tap((oidcLoginResponse: OidcLoginEntity) => {
            const url = new URL(oidcLoginResponse.currentState);
            const queryParams: any = {}; // TODO: Use QueryParam type?

            url.searchParams.append('isOidcCallback', 'true');

            url.searchParams.forEach((value: string, key: string) => {
              // Leave out outdated params from url. At this point there are still old/leftover
              // params which are misleading post OIDC callback:
              if (!['error', 'error_description', 'internal_error_code'].includes(key)) {
                queryParams[key] = value;
              }
            });

            this._router.navigate([url.pathname], { queryParams }).catch();
          }),
          catchError((response: HttpErrorResponse) => {
            if (response.error.error === 'USER_DEACTIVATED') {
              this._redirectToErrorPage(ErrorPageErrorsEnum.USER_DEACTIVATED);
            } else {
              this._logOidcError(
                this._oauthAuthorizeResponse.state,
                response.error.error,
                'POST_LOGIN_FAILED',
                JSON.stringify({
                  oauthAuthorizeResponse: this._oauthAuthorizeResponse,
                  idApiResponse: response,
                })
              );
              this._redirectToErrorPage();
            }
            return throwError(() => response);
          })
        )
        .subscribe();
    } else {
      let redirectToErrorPage = true;

      // User actively cancelled, show login-form again
      if (this._oauthAuthorizeResponse.error && this._oauthAuthorizeResponse.error === 'IDP-3200') {
        redirectToErrorPage = false;
        void this._router.navigate(['/']);
      }

      this._logOidcError(
        this._oauthAuthorizeResponse.state,
        this._oauthAuthorizeResponse.error || 'unknown',
        this._oauthAuthorizeResponse.errorDescription,
        JSON.stringify({ oauthAuthorizeResponse: this._oauthAuthorizeResponse })
      );

      if (redirectToErrorPage) {
        this._redirectToErrorPage();
      }
    }
  }

  private _logOidcError(state?: string, error?: string, errorDescription?: string, extraInfo?: string): void {
    this._authenticationService.oidcPostError(state, error, errorDescription, extraInfo).pipe(take(1)).subscribe();
  }

  private _redirectToErrorPage(error: ErrorPageErrorsEnum = ErrorPageErrorsEnum.LOGIN) {
    void this._router.navigate(['error'], { queryParams: { error_type: error } });
  }
}
