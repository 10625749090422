/**
 *
 */
export class AlternativeLinksItemEntity {
  active: boolean = false;
  callback: {
    fn: (...args) => void;
    bind: any;
    args: any[];
  };
  iconPath?: string;
  id: string;
  label: string;

  constructor(options: {
    callback: {
      fn: (...args) => void;
      bind: any;
      args: any[];
    };
    id: string;
    label: string;
    iconPath?: string;
  }) {
    this.callback = options.callback;
    this.iconPath = options.iconPath;
    this.id = options.id;
    this.label = options.label;
  }

  itemClicked() {
    this.callback.fn.apply(this.callback.bind, this.callback.args);
  }
}
