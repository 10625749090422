import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { LoginFormRedirect } from '@core/entities/login/login-form-redirect.entity';
import { LoginSession } from '@core/entities/login/login-session.entity';
import { LoginResponse } from '@core/entities/response/login-response.entity';
import { UserFeedbackEntity } from '@core/entities/user/user-feedback.entity';
import { ElementRefHelper } from '@core/helpers/element-ref.helper';
import { OauthAuthorizeRedirectQueryParamsEntity } from '@core/entities/query-params/oauth-authorize-redirect-query-params.entity';

/**
 * TODO: Remove concept. Solve w/ service and / or interface instead
 */
@Directive()
export abstract class BaseLoginFormComponent implements AfterViewInit {
  @Output()
  showComponent: EventEmitter<LoginFormRedirect> = new EventEmitter();

  @Output()
  loginCallback: EventEmitter<LoginResponse> = new EventEmitter();

  @Input()
  loginSession: LoginSession;

  @Input()
  oauthAuthorizeRedirectQueryParams?: OauthAuthorizeRedirectQueryParamsEntity;

  @ViewChild('focusRef', { static: false })
  focusRef;

  errorMessages: UserFeedbackEntity[] = [];
  processing: boolean = false;
  submitted: boolean = false;

  private _messagesRef: ElementRef;

  @ViewChild('feedbackMessagesRef', { read: ElementRef, static: false })
  set messagesRef(elRef: ElementRef) {
    this._messagesRef = elRef;
  }

  /**
   * Focus the element with #focusRef
   */
  ngAfterViewInit(): void {
    if (this.focusRef) {
      this.focusRef.nativeElement.focus();
    }
  }

  /**
   * Redirect user to another login form component
   *
   * @param redirect
   */
  redirectTo(redirect: LoginFormRedirect): void {
    this.showComponent.emit(redirect);
  }

  login(loginResponse: LoginResponse): void {
    this.loginCallback.emit(loginResponse);
  }

  setErrorMessages(userFeedback: UserFeedbackEntity[]): void {
    this.errorMessages = userFeedback;

    if (this.errorMessages.length > 0) {
      ElementRefHelper.scrollTo(this._messagesRef);
    }
  }
}
